import React, { useState, useContext, useRef, useEffect } from "react";
import { motion } from "framer-motion";

import { MenuContext } from "./MenuContext";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { Link } from "react-router-dom";

export const DropdownMenu1 = ({ setIsMenuToggled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [arrowDirection, setArrowDirection] = useState("down");
  const { openMenu } = useContext(MenuContext);
  const menuRef = useRef();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    setArrowDirection(arrowDirection === "down" ? "up" : "down");
  };

  const handleLinkClick = () => {
    setIsMenuToggled(false);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className="flex flex-col items-center " ref={menuRef}>
      <div
        className={`text-black text-3xl sm:text-xl font-bold sm:font-semibold font-golos leading-7 cursor-pointer hover:text-red transition duration-100 flex flex-row gap-2${
          openMenu === "menu1" ? "open" : ""
        }`}
        onClick={toggleMenu}
      >
        <span className="menu-title ">О программе</span>
        <span
          className={`mt-1 sm:mt-[6px] ${
            arrowDirection === "down " ? "rotate-180" : "rotate-0"
          }`}
        >
          {arrowDirection === "down" ? <IoIosArrowDown /> : <IoIosArrowUp />}
        </span>
      </div>
      {isOpen && (
        <motion.div
          initial={{ height: 0 }}
          animate={{ height: "auto" }}
          exit={{ height: 0 }}
          transition={{ duration: 0.2 }}
          className="mt-10 p-5 relative md:absolute bg-white rounded-lg flex flex-col"
        >
          <Link
            to="/about"
            className=" text-black text-lg font-normal font-golos leading-loose cursor-pointer hover:text-red transition duration-100"
            onClick={handleLinkClick}
          >
            О программе
          </Link>
          <Link
            to="/about?tab=countries"
            className="text-black text-lg font-normal font-golos leading-loose cursor-pointer hover:text-red transition duration-100"
            onClick={handleLinkClick}
          >
            Страны — участницы
          </Link>

          <Link
            to="/about?tab=expertsovet"
            className="text-black text-lg font-normal font-golos leading-loose cursor-pointer hover:text-red transition duration-100"
            onClick={handleLinkClick}
          >
            Экспертный совет
          </Link>
          <Link
            to="/about?tab=presscenter"
            className=" text-black text-lg font-normal font-golos leading-loose cursor-pointer hover:text-red transition duration-100"
            onClick={handleLinkClick}
          >
            Пресс-центр
          </Link>
        </motion.div>
      )}
    </div>
  );
};

export const DropdownMenu2 = ({ setIsMenuToggled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [arrowDirection, setArrowDirection] = useState("down");
  const { openMenu } = useContext(MenuContext);
  const menuRef = useRef();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    setArrowDirection(arrowDirection === "down" ? "up" : "down");
  };

  const handleLinkClick = () => {
    setIsMenuToggled(false);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="flex flex-col items-center" ref={menuRef}>
      <div
        className={`text-black text-3xl sm:text-xl font-bold sm:font-semibold font-golos leading-7 cursor-pointer hover:text-red transition duration-100 flex flex-row gap-2${
          openMenu === "menu1" ? "open" : ""
        }`}
        onClick={() => {
          toggleMenu();
          // setShowText(true); // Показать текст после открытия блока
        }}
      >
        <span className="menu-title">Статьи</span>
        <span
          className={`mt-1 sm:mt-[6px] ${
            arrowDirection === "down " ? "rotate-180" : "rotate-0"
          }`}
        >
          {arrowDirection === "down" ? <IoIosArrowDown /> : <IoIosArrowUp />}
        </span>
      </div>
      {isOpen && (
        <motion.div
          initial={{ height: 0 }}
          animate={{ height: "auto" }}
          exit={{ height: 0 }}
          transition={{ duration: 0.2 }}
          className="mt-10 p-5 relative md:absolute bg-white rounded-lg flex flex-col"
        >
          <Link
            to="/states?tab=cultura"
            className="text-black text-lg font-normal font-golos leading-loose cursor-pointer hover:text-red transition duration-100"
            onClick={handleLinkClick}
          >
            Культура
          </Link>
          <Link
            to="/states?tab=obrazovanie"
            className="text-black text-lg font-normal font-golos leading-loose cursor-pointer hover:text-red transition duration-100"
            onClick={handleLinkClick}
          >
            Образование
          </Link>
          <Link
            to="/states?tab=blagotvoritelnost"
            className="text-black text-lg font-normal font-golos leading-loose cursor-pointer hover:text-red transition duration-100"
            onClick={handleLinkClick}
          >
            Благотворительность
          </Link>
          {/* Добавьте другие ссылки здесь */}
        </motion.div>
      )}
    </div>
  );
};
