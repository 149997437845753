import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const TestModal = ({ x, y, markerData, setHoveredMarker }) => {
  const navigate = useNavigate();

  // Функция для обработки клика вне модального окна
  const handleClickOutside = (event) => {
    const modal = document.getElementById("modal");
    if (modal && !modal.contains(event.target)) {
      setHoveredMarker(null);
    }
  };

  // Добавляем слушатель событий для клика вне модального окна
  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []); // Пустой массив зависимостей гарантирует, что эффект запустится один раз

  // Функция для навигации
  const handleNavigate = () => {
    navigate(markerData.url);
    setHoveredMarker(null);
  };

  // Проверка наличия markerData
  if (!markerData) return null;
  // Примените x и y для позиционирования модального окна
  const isMobile = window.innerWidth <= 600; // Определяем пороговое значение для мобильных экранов

  const modalStyle = {
    position: "fixed",
    top: isMobile ? "50%" : `${y}px`,
    left: isMobile ? "50%" : `${x}px`,
    transform: isMobile ? "translate(-50%, -50%)" : "none",
    // Добавьте дополнительные стили по необходимости
    borderRadius: "8px",
    // Убедитесь, что модальное окно отображается поверх других элементов
  };

  return (
    <>
      {/* Крестик в правом верхнем углу всего экрана */}
      {isMobile && (
        <button
          className="fixed top-2 right-2 z-50 w-8 h-8"
          onClick={() => setHoveredMarker(null)}
        >
          <img src="/crosss.svg" alt="cross" />
        </button>
      )}

      {/* Модальное окно */}
      <div
        id="modal-overlay"
        className="modal-overlay fixed inset-0 flex justify-center items-center z-40 bg-black/20 md:bg-transparent"
        onClick={handleClickOutside}
      >
        <div
          id="modal"
          style={modalStyle}
          className="modal bg-white/80 z-50 w-11/12 sm:w-[480px] h-max relative"
          onClick={(e) => e.stopPropagation()}
        >
          {/* Отобразите данные маркера в модальном окне */}
          <div className="flex flex-col justify-start items-start gap-[8px] m-4">
            <div className="flex justify-start items-center gap-2 text-red text-[27px] leading-tight font-bold font-golos">
              <img src={markerData.flag} alt="" className="w-[25px] h-[30px]" />
              {markerData.title}
            </div>
            <p className="text-black text-lg font-medium font-golos leading-tight">
              {markerData.content}
            </p>
            <button
              onClick={handleNavigate}
              className="text-red text-xl font-bold font-golos underline leading-none"
            >
              Подробнее...
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestModal;
