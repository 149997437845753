import React from "react";
import Footer from "../Footer";
import { Link } from "react-router-dom";

const Page10072023 = () => {
  return (
    <div className="flex flex-col justify-start items-start gap-[40px] sm:gap-[90px] ">
      <div className="w-full sm:w-5/6 flex flex-col justify-start items-start gap-[20px] sm:gap-[40px] ">
        <div className=" text-black text-2xl sm:text-5xl font-bold font-golos leading-snug mt-24 sm:mt-40 ">
          В Русском доме состоялась встреча Молодежного клуба, посвященная
          Всероссийскому празднику «День семьи, любви и верности
        </div>
        <div className="text-neutral-950 text-[18px] sm:text-[27px] font-normal sm:font-semibold font-golos leading-loose ">
          10 июля 2023 года
        </div>
        <div className="text-black text-lg font-golos font-normal leading-normal flex flex-col gap-6">
          <img src="/pic4.jpg"></img>
          <p>
            {" "}
            Руководитель клуба Оксана Лихацкая предоставила ребятам возможность
            воочию увидеть медаль «За любовь и верность», которая вручается
            парам, живущим в браке более 25 лет, получившим известность среди
            сограждан крепостью семейных устоев и воспитавшим детей достойными
            членами общества, и рассказала о символе праздника — ромашке.
          </p>
          <img src="/pic5.jpg"></img>
          <p>
            Участники встречи не только подготовили презентации на русском языке
            об истории и традициях этого молодого, но очень важного праздника,
            который отмечается в России 8 июля, но и сыграли в интеллектуальную
            викторину. Напоследок члены Молодежного клуба при Русском доме в
            Каире своими руками нарисовали очень красивые семейные древа.
          </p>
          <img src="/pic6.jpg"></img>
        </div>
      </div>
      <Link
        to={"/about?tab=presscenter"}
        className="mb-[40px] px-[38px] py-[11px] xs:px-11 xs:py-[16px] text-base leading-tight shadow-[-1px_1px_11px_6px_rgba(212,18,22,0.24)]   font-semibold border-gradient-rainred rounded-[15px] xs:rounded-[24px] justify-center  gap-2.5 inline-flex text-red xs:text-[24px] xs:font-bold xs:leading-7  transition ease-in-out hover:-translate-y-1  hover:delay-50 duration-100"
      >
        Вернуться в пресс-центр
      </Link>
      <Footer />
    </div>
  );
};

export default Page10072023;
