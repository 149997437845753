import React from "react";
import Footer from "../Footer";
import { Link } from "react-router-dom";

const Page21072023 = () => {
  return (
    <div className="flex flex-col justify-start items-start gap-[40px] sm:gap-[90px] ">
      <div className="w-full sm:w-5/6 flex flex-col justify-start items-start gap-[20px] sm:gap-[40px] ">
        <div className=" text-black text-2xl sm:text-5xl font-bold font-golos leading-snug mt-24 sm:mt-40 ">
          {" "}
          Вице-спикер Госдумы Анна Кузнецова назвала важнейшие законы по
          сохранению традиционных ценностей
        </div>
        <div className="text-neutral-950 text-[18px] sm:text-[27px] font-normal sm:font-semibold font-golos leading-loose ">
          21 июля 2023 года{" "}
        </div>
        <div className="text-black text-lg font-golos font-normal leading-normal flex flex-col gap-6">
          <img src="/pic8.jpg"></img>
          <p>
            {" "}
            Запрет пропаганды нетрадиционных сексуальных отношений и смены пола,
            запрет услуг суррогатного материнства для иностранцев и лиц без
            гражданства — эти и другие инициативы стали основой сохранения
            традиционных ценностей в России. По словам вице-спикера Госдумы Анны
            Кузнецовой, эти нормы не только приняты, но уже и работают. Об этом
            она заявила 20 июля в ходе заседания межфракционной рабочей группы.
          </p>
          <p>
            Зампред палаты подчеркнула, что 809-й указ президента «Об
            утверждении Основ государственной политики по сохранению и
            укреплению традиционных российских духовно-нравственных ценностей»
            стал надежным фильтром для анализа законодательных инициатив.
            Парламентарии работали в двух направлениях: продвигали нужные
            инициативы, руководствуясь основными тезисами, задачами и целями
            документа, а также не допускали законопроекты, которые в той или
            иной части их нарушали.
          </p>

          <p>
            «Такие инициативы, которые не соответствовали этому указу или
            нарушали его, мы не допустили ни до слушаний в Госдуме, ни до
            внесения. Безусловно, ряд инициатив, которые соответствуют указу
            809, были реализованы», — сказала Анна Кузнецова.
          </p>
          <p>
            На днях Президент России Владимир Путин сказал: «Самое главное —
            посвящение себя стране, в конечном итоге это борьба за будущее наших
            детей». По мнению Кузнецовой, в рабочей группе по законодательной
            реализации государственной политики в сфере сохранения и укрепления
            традиционных российских духовно-нравственных ценностей иначе
            действовать и не могут.
          </p>

          <p>
            Кузнецова также сообщила, что недавно Минтруд представил результаты
            оценки демографической ситуации в России и проанализировал, какие
            меры поддержки могут помочь улучшить демографическую ситуацию в
            конкретных регионах. Такие данные, заметила политик, еще раз
            подтверждают, что сегодня одна из важнейших тем, отраженных в том
            числе в указе 809, — это, безусловно, защита страны и
            демографический потенциал.
          </p>
          <p>
            «Если говорить о конкретных шагах, которые были реализованы, мы не
            можем не сказать о том, что был принят ряд законов, которые уже
            работают: это запрет ЛГБТ-пропаганды, запрет суррогатного
            материнства для иностранцев, однако мы идем дальше вперед — запрет
            смены пола и все, что связано с этим, реализовано», — подчеркнула
            вице-спикер.
          </p>

          <p className="text-red">
            Анастасия Островкова,{" "}
            <span className="underline">«Парламентская газета»</span>
          </p>
        </div>
      </div>
      <Link
        to={"/about?tab=presscenter"}
        className="mb-[40px] px-[38px] py-[11px] xs:px-11 xs:py-[16px] text-base leading-tight shadow-[-1px_1px_11px_6px_rgba(212,18,22,0.24)]   font-semibold border-gradient-rainred rounded-[15px] xs:rounded-[24px] justify-center  gap-2.5 inline-flex text-red xs:text-[24px] xs:font-bold xs:leading-7  transition ease-in-out hover:-translate-y-1  hover:delay-50 duration-100"
      >
        Вернуться в пресс-центр
      </Link>
      <Footer />
    </div>
  );
};

export default Page21072023;
