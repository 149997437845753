import {
  HashRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
} from "react-router-dom";
import Russia from "./scenes/Countries/Russia";
import Belarus from "./scenes/Countries/Belarus";
import Egypt from "./scenes/Countries/Egypt";
import Serbia from "./scenes/Countries/Serbia";
import Uzbekistan from "./scenes/Countries/Uzbekistan";
import Armenia from "./scenes/Countries/Armenia";
import Abkhazia from "./scenes/Countries/Abkhazia";
import Lebanon from "./scenes/Countries/Lebanon";
import Cyprus from "./scenes/Countries/Cyprus";
import Kyrgyzstan from "./scenes/Countries/Kyrgyzstan";
import Syria from "./scenes/Countries/Syria";
import Page09042024 from "./scenes/states/Page09042024";
import Page01042024 from "./scenes/states/Page01042024";
import Page04042023 from "./scenes/states/Page04042023";
import Page05052023 from "./scenes/states/Page05052023";
import Page08072023 from "./scenes/states/Page08072023";
import Page10072023 from "./scenes/states/Page10072023";
import Page11072023 from "./scenes/states/Page11072023";
import Page21072023 from "./scenes/states/Page21072023";
import Page13062023 from "./scenes/states/Page13062023";
import Page27092023 from "./scenes/states/Page27092023";
import Page14062023 from "./scenes/states/Page14062023";
import Page15062023 from "./scenes/states/Page15062023";
import Page16052023 from "./scenes/states/Page16052023";
import Page19042024 from "./scenes/states/Page19042024";
import Page30042024 from "./scenes/states/Page30042024";
import Page03052024 from "./scenes/states/Page03052024";
import Page18052024 from "./scenes/states/Page18052024";
import Contest from "./scenes/states/Contest";
import Navbar from "./scenes/Navbar";
import Landing from "./scenes/Landing";
import { useState, useEffect } from "react";
import AboutPage from "./scenes/AboutPage"; // Импортируйте страницу "О программе"
import NewsComponent from "./scenes/NewsComponent";
import States from "./scenes/States";
import CountryPage from "./scenes/CountryPage";
import Contacts from "./scenes/Contacts";
import Svet from "./scenes/states/Svet";

function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location]);

  return null;
}
function App() {
  const [selectedPage, setSelectedPage] = useState("Главная");
  const [isTopOfPage, setIsTopOfPage] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY === 0) {
        setIsTopOfPage(true);
        setSelectedPage("Главная");
      }
      if (window.scrollY !== 0) setIsTopOfPage(false);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      if (window) window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="app bg-[#f6f6f6]}">
      <Navbar
        isTopOfPage={isTopOfPage}
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
      />
      <div
        className="w-11/12 sm:w-5/6 mx-auto md:h-full"
        style={{ maxWidth: "1400px" }}
      >
        {/* {isAboveMediumScreens && (
          <DotGroup
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
          />
        )} */}
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Landing />} />
          {/* Маршрут для главной страницы */}
          <Route path="/about" element={<AboutPage />} />
          <Route path="/states" element={<States />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/russia" element={<Russia />} />
          <Route path="/belarus" element={<Belarus />} />
          <Route path="/serbia" element={<Serbia />} />
          <Route path="/cyprus" element={<Cyprus />} />
          <Route path="/egypt" element={<Egypt />} />
          <Route path="/uzbekistan" element={<Uzbekistan />} />
          <Route path="/syria" element={<Syria />} />
          <Route path="/lebanon" element={<Lebanon />} />
          <Route path="/kyrgyzstan" element={<Kyrgyzstan />} />
          <Route path="/armenia" element={<Armenia />} />
          <Route path="/abkhazia" element={<Abkhazia />} />
          <Route path="/contest" element={<Contest />} />
          <Route path="/svet" element={<Svet />} />

          <Route path="/page04042023" element={<Page04042023 />} />
          <Route path="/page01042024" element={<Page01042024 />} />
          <Route path="/page09042024" element={<Page09042024 />} />
          <Route path="/page19042024" element={<Page19042024 />} />
          <Route path="/page30042024" element={<Page30042024 />} />
          <Route path="/page05052023" element={<Page05052023 />} />
          <Route path="/page08072023" element={<Page08072023 />} />
          <Route path="/page10072023" element={<Page10072023 />} />
          <Route path="/page11072023" element={<Page11072023 />} />
          <Route path="/page21072023" element={<Page21072023 />} />
          <Route path="/page13062023" element={<Page13062023 />} />
          <Route path="/page27092023" element={<Page27092023 />} />
          <Route path="/page14062023" element={<Page14062023 />} />
          <Route path="/page15062023" element={<Page15062023 />} />
          <Route path="/page16052023" element={<Page16052023 />} />
          <Route path="/page03052024" element={<Page03052024 />} />
          <Route path="/page18052024" element={<Page18052024 />} />

          {/* Маршрут для страницы "О программе" */}
        </Routes>
      </div>
    </div>
  );
}

export default function WrappedApp() {
  return (
    <Router>
      <App />
    </Router>
  );
}
