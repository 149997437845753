import React from "react";
import Footer from "../Footer";
import { Link } from "react-router-dom";

const Page05052023 = () => {
  return (
    <div className="flex flex-col justify-start items-start gap-[40px] sm:gap-[90px] ">
      <div className="w-full sm:w-5/6 flex flex-col justify-start items-start gap-[20px] sm:gap-[40px] ">
        <div className=" text-black text-2xl sm:text-5xl font-bold font-golos leading-snug mt-24 sm:mt-40 ">
          В Айя-Напе прошел праздник, посвященный традиционным семейным
          ценностям
        </div>
        <div className="text-neutral-950 text-[18px] sm:text-[27px] font-normal sm:font-semibold font-golos leading-loose ">
          5 мая 2023 года
        </div>
        <div className="text-black text-lg font-golos font-normal leading-normal flex flex-col gap-6">
          <p>
            {" "}
            Праздник, посвященный защите традиционных семейных ценностей, прошел
            в воскресенье на главной набережной города Айя-Напа возле
            скульптурной композиции "Семья - залог единства". Его участники,
            державшие в руках флаги с надписью "Папа, мама, я - счастливая
            семья!" и российские триколоры, отмечали ведущую роль РФ как гаранта
            незыблемости института семьи.
          </p>
          <p>
            "Мероприятие направлено против пропаганды ЛГБТ и трансгендерности,
            которые насаждаются сегодня в кипрских школах в соответствии с
            ценностными установками из Брюсселя, - рассказал корреспонденту ТАСС
            один из организаторов, активист Неофитос Филиппидис. - Мы, западные
            люди, должны найти способ сотрудничать с такой великой и мощной
            страной, как Россия. А Кипр должен прекратить обниматься с теми
            западными государствами, которые не только не помогают нам, а,
            наоборот, ввергают в катастрофу, ведут к уничтожению традиций семьи
            и нашей религии. Россия - наш самый лучший, самый честный и
            настоящий друг из всех стран".
          </p>
          <p>
            На праздник пришло много семей с детьми. Никосийский
            образовательно-методический центр "Пионер" провел тематические
            конкурсы и викторины с раздачей подарков, а также красочный концерт
            с участием большого числа творческих коллективов. Кипрские активисты
            разработали символ праздника - ромашку, лепестки которой разукрашены
            в цвета флага России. Флаги с этим символом украсили набережную
            Айя-Напы.
          </p>
          <p>
            <a
              href="https://bigasia.ru/rossijskie-sootechestvenniki-kipra-otmetili-prazdnik-semejnyh-czennostej/ "
              className="underline text-red"
            >
              Ссылка на источник
            </a>
          </p>
        </div>
      </div>
      <Link
        to={"/about?tab=presscenter"}
        className="mb-[40px] px-[38px] py-[11px] xs:px-11 xs:py-[16px] text-base leading-tight shadow-[-1px_1px_11px_6px_rgba(212,18,22,0.24)]   font-semibold border-gradient-rainred rounded-[15px] xs:rounded-[24px] justify-center  gap-2.5 inline-flex text-red xs:text-[24px] xs:font-bold xs:leading-7  transition ease-in-out hover:-translate-y-1  hover:delay-50 duration-100"
      >
        Вернуться в пресс-центр
      </Link>
      <Footer />
    </div>
  );
};

export default Page05052023;
