import React from "react";
import Footer from "../Footer";
import { Link } from "react-router-dom";

const Page15062023 = () => {
  return (
    <div className="flex flex-col justify-start items-start gap-[40px] sm:gap-[90px] ">
      <div className="w-full sm:w-5/6 flex flex-col justify-start items-start gap-[20px] sm:gap-[40px] ">
        <div className=" text-black text-2xl sm:text-5xl font-bold font-golos leading-snug mt-24 sm:mt-40 ">
          Программа «Семья и мир» расширяет круг единомышленников
        </div>
        <div className="text-neutral-950 text-[18px] sm:text-[27px] font-normal sm:font-semibold font-golos leading-loose ">
          15 июня 2023 года
        </div>
        <img src="/1.jpg"></img>
        <div className="text-black text-lg font-golos font-normal leading-normal flex flex-col gap-6">
          <p>
            {" "}
            Программа «Семья и мир», разработанная Комиссией по защите семьи
            «Единой России», Россотрудничеством, МОО «Союз православных женщин»
            теперь поддержана Отделом внешних церковных связей Московского
            патриархата.
          </p>
          <p>
            Заместитель Председателя Государственной Думы ФС РФ, сопредседатель
            Правления МОО «Союз православных женщин» А.Ю.Кузнецова встретилась с
            председателем Отдела внешних церковных связей и постоянным членом
            Священного Синода Русской Православной Церкви митрополитом
            Волоколамским Антонием.
          </p>

          <p>
            В своём телеграм-канале она отметила, что встреча с владыкой
            Антонием Корсунским прошла очень тепло. Задача объединения семей с
            детьми, наших соотечественников, всех, кто понимает, что обычным
            нормальным семьям сегодня угрожает опасность — одна из центральных
            нашей программы. Уже прошли встречи, мероприятия в Белоруссии,
            Сирии, запланированы — в Абхазии, Армении, Египте, Кипре,
            Узбекистане, Сербии, Киргизии и других государствах.
          </p>
          <p>
            Появляется все больше единомышленников, тех, кто поддерживает
            традиционные семейные ценности. Ради наших детей, будущих поколений
            важно одержать победу и на этом поле сражений за наши ценности, за
            то, что дорого каждому из нас.
          </p>
        </div>
      </div>
      <Link
        to={"/about?tab=presscenter"}
        className="mb-[40px] px-[38px] py-[11px] xs:px-11 xs:py-[16px] text-base leading-tight shadow-[-1px_1px_11px_6px_rgba(212,18,22,0.24)]   font-semibold border-gradient-rainred rounded-[15px] xs:rounded-[24px] justify-center  gap-2.5 inline-flex text-red xs:text-[24px] xs:font-bold xs:leading-7  transition ease-in-out hover:-translate-y-1  hover:delay-50 duration-100"
      >
        Вернуться в пресс-центр
      </Link>
      <Footer />
    </div>
  );
};

export default Page15062023;
