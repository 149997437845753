import React from "react";
import Footer from "./Footer";

const Contacts = () => {
  return (
    <div
      className="w-full flex flex-col gap-10 items-start justify-center"
      style={{ position: "relative" }}
    >
      <div className=" text-red text-5xl md:text-6xl font-bold font-golos leading-[72px] mt-28">
        Контакты
      </div>
      <div className="flex flex-col sm:flex-row justify-center gap-[20px] items-start sm:items-center  ">
        <img src="/oprogramme.png" alt="bear" className="max-w-[280px]" />
        <div className="flex flex-col items-start justify-start gap-[50px]">
          <div className="flex flex-col items-start justify-start gap-2">
            <div className="text-zinc-800 text-xl md:text-2xl font-bold leading-tight ">
              Международная программа реализации инициатив
              <br />
              по защите традиционных семейных ценностей{" "}
            </div>
            <div className=" text-red text-4xl md:text-6xl font-bold font-golos leading-[72px] ">
              «Семья и мир»
            </div>
          </div>
          <div className=" ml-0 mr-auto sm:mr-0 mt-4 sm:mt-0 flex flex-col justify-start gap-3 align-self-start">
            {/* <SearchComponent  /> */}
            <div className="sm:ml-auto ml-0 text-black text-xl font-normal sm:font-semibold font-golos leading-7">
              <a href="tel:+74951502556">+7 (495) 150-25-56</a>
            </div>
            <div className=" ml-0 text-black text-xl font-normal sm:font-semibold font-golos underline leading-7">
              <a href="mailto:9177188@mail.ru">9177188@mail.ru</a>
            </div>
            <div className=" ml-0 flex flex-row justify-start gap-6 mt-1">
              <a href="https://vk.com">
                <img
                  src="/vkontakte.svg"
                  className="sm:ml-auto ml-0 "
                  alt="SVG 1"
                />
              </a>
              <a href="https://web.telegram.org">
                <img
                  src="/telega.svg"
                  className="sm:ml-auto ml-0 "
                  alt="SVG 2"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-[50px]">
        <ul className="text-black text-[22px] font-semibold font-golos flex flex-col gap-5">
          Миссия программы:
          <li className=" font-normal font-golos">
            Объединение мирового сообщества в целях налаживания диалога по
            актуальным вопросам сохранения, укрепления <br />и продвижения
            традиционных духовно-нравственных ценностей
          </li>
        </ul>
        <ul className="text-black text-[22px] font-semibold font-golos flex flex-col gap-5">
          Цели программы:
          <li className=" font-normal font-golos">
            Объединение усилий организаций, учреждений и граждан по увеличению
            эффективности работы в сфере отстаивания традиционных духовно —
            нравственных и семейных ценностей
          </li>
          <li className=" font-normal font-golos">
            Сохранение, укрепление и продвижение традиционных духовно —
            нравственных и семейных ценностей
          </li>
          <li className=" font-normal font-golos">
            Противодействие распространению деструктивной идеологии
          </li>
        </ul>
        <ul className="text-black text-[22px] font-semibold font-golos flex flex-col gap-5">
          Задачи программы:
          <li className=" font-normal font-golos">
            Сохранение, укрепление и продвижение традиционных духовно —
            нравственных и семейных ценностей
          </li>
          <li className=" font-normal font-golos">
            Выработка экспертных мнений и рекомендаций по решению актуальных
            проблем в области всесторонней поддержки семьи
          </li>
          <li className=" font-normal font-golos">
            Разработка решений актуальных проблем, с которыми сталкиваются
            государства и традиционная мировая общественность
          </li>
        </ul>
      </div>
      <div className="w-full flex flex-col justify-center gap-[40px] ">
        <div className=" text-red text-5xl md:text-6xl font-bold fomt-golos ">
          Кураторы программы:
        </div>
        <div
          className="flex h-max md:h-[378px] flex-col md:flex-row items-center justify-start
        gap-[40px] md:gap-[80px] mx-auto w-full"
          style={{ flexShrink: 0 }}
        >
          <img
            src="/1stcurator.png"
            alt="one"
            className="w-[300px]  h-[378px]"
          />
          <div className=" md:h-[378px] flex justify-center gap-5 flex-col flex-shrink-1 flex-grow-0 items-center md:items-start w-5/6 md:w-full">
            <div className="text-black text-[40px] font-bold font-golos leading-[48px]">
              Дмитриевская Наталья Вильевна{" "}
            </div>
            <div className=" text-black text-lg font-medium font-golos leading-normal">
              сопредседатель МОО «Союз православных женщин», член Совета
              Благотворительного фонда «Жизнь как чудо», член Комиссии
              Общественной Палаты Российской Федерации по вопросам социального
              партнерства, попечения и развитию инклюзивных практик
            </div>
            <a
              href="mailto:spg.moskva@gmail.com"
              className="text-red font-golos font-semibold text-[22px]"
            >
              spg.moskva@gmail.com
            </a>
          </div>
        </div>
        <div
          className="flex h-max md:h-[378px] flex-col md:flex-row items-center justify-start
        gap-[40px] md:gap-[80px] mx-auto w-full"
          style={{ flexShrink: 0 }}
        >
          <img
            src="/2ndcurator.png"
            alt="one"
            className="w-[300px]  h-[378px]"
          />
          <div className=" md:h-[378px] flex justify-center gap-5 flex-col flex-shrink-1 flex-grow-0 items-center md:items-start w-5/6 md:w-full">
            <div className="text-black text-[40px] font-bold font-golos leading-[48px]">
              Сипягина Елена Александровна
            </div>
            <div className=" text-black text-lg font-medium font-golos leading-normal">
              руководитель комитета по социальной работе и благотворительности
              МОО «Союз православных женщин», член правления РОО «Костромское
              землячество»
            </div>
            <a
              href="mailto:elena.sipyagina77@gmail.com"
              className="text-red font-golos font-semibold text-[22px]"
            >
              elena.sipyagina77@gmail.com
            </a>
          </div>
        </div>
        <div
          className="flex h-max md:h-[378px] flex-col md:flex-row items-center justify-start
          gap-[40px] md:gap-[80px] mx-auto w-full"
          style={{ flexShrink: 0 }}
        >
          <img
            src="/3rdcurator.png"
            alt="one"
            className="w-[300px]  h-[378px]"
          />
          <div className=" md:h-[378px] flex justify-center gap-5 flex-col flex-shrink-1 flex-grow-0 items-center md:items-start w-5/6 md:w-full">
            <div className="text-black text-[40px] font-bold font-golos leading-[48px]">
              Парсаданян Анна Ивановна
            </div>
            <div className=" text-black text-lg font-medium font-golos leading-normal">
              куратор проектов МОО «Союз православных женщин»
            </div>
            <a
              href="mailto:parsadanananna2@gmail.com"
              className="text-red font-golos font-semibold text-[22px]"
            >
              parsadanananna2@gmail.com
            </a>
          </div>
        </div>
      </div>
      <Footer />;
    </div>
  );
};

export default Contacts;
