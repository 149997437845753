import React from "react";
import Footer from "../Footer";
import { Link } from "react-router-dom";

const Page27092023 = () => {
  return (
    <div className="flex flex-col justify-start items-start gap-[40px] sm:gap-[90px] ">
      <div className="w-full sm:w-5/6 flex flex-col justify-start items-start gap-[20px] sm:gap-[40px] ">
        <div className=" text-black text-2xl sm:text-5xl font-bold font-golos leading-snug mt-24 sm:mt-40 ">
          Эксперты программы «Миссия Добро» завершили свою работу на полях
          международного благотворительного форума «Ауладна»
        </div>
        <div className="text-neutral-950 text-[18px] sm:text-[27px] font-normal sm:font-semibold font-golos leading-loose ">
          27 сентября 2023 года
        </div>
        <div className="text-black text-lg font-golos font-normal leading-normal flex flex-col gap-6">
          <p>
            В провинции Бехейра волонтеры программы «Миссия Добро» провели
            заключительный мастер-класс для детей и подростков
          </p>
          <p>
            Учитель-логопед Елена Швиткова из реабилитационного центра для детей
            и подростков с ограниченными возможностями «Дарина» выступила перед
            египетским коллегами с докладом о работе с детьми с особенными
            потребностями в России, рассказала о методах психологической и
            педагогической поддержке детей и их родителей, роли игры в речевой
            терапии. Также при участии специалистов, Татьяны Васильевой из
            центра «Дарина» и Веры Елисеевой из центра «Добро пожаловать!», были
            организованы развивающие и сенсорные игры для ребят. В завершение
            рабочего дня российские эксперты дали интервью египетскому
            телевидении и пообщались с иностранными коллегами.
          </p>
          <p>
            Цель программы «Миссия Добро» – оказание помощи людям и организациям
            по всему миру с привлечением специалистов из России в формате
            волонтерских миссий.
          </p>
          <p>
            Международный гуманитарный проект реализуют Россотрудничество,
            Ассоциация волонтерских центров и Русская Гуманитарная Миссия.
          </p>
          <div className="grid sm:grid-rows-3 grid-rows-6 grid-flow-col grid-cols-1 sm:grid-cols-2 gap-4">
            <img
              src="/dobro/1.png"
              alt="one"
              className="object-cover h-[300px] max-w-[300px] rounded-lg"
            />
            <img
              src="/dobro/2.png"
              alt="two"
              className="object-cover h-[300px] max-w-[300px] rounded-lg"
            />
            <img
              src="/dobro/3.png"
              alt="three"
              className="object-cover h-[300px] max-w-[300px] rounded-lg"
            />
            <img
              src="/dobro/4.png"
              alt="four"
              className="object-cover h-[300px] max-w-[300px] rounded-lg"
            />
            <img
              src="/dobro/5.png"
              alt="five"
              className="object-cover h-[300px] max-w-[300px] rounded-lg"
            />
            <img
              src="/dobro/6.png"
              alt="six"
              className="object-cover h-[300px] max-w-[300px] rounded-lg"
            />
          </div>
        </div>
      </div>
      <Link
        to={"/about?tab=presscenter"}
        className="mb-[40px] px-[38px] py-[11px] xs:px-11 xs:py-[16px] text-base leading-tight shadow-[-1px_1px_11px_6px_rgba(212,18,22,0.24)]   font-semibold border-gradient-rainred rounded-[15px] xs:rounded-[24px] justify-center  gap-2.5 inline-flex text-red xs:text-[24px] xs:font-bold xs:leading-7  transition ease-in-out hover:-translate-y-1  hover:delay-50 duration-100"
      >
        Вернуться в пресс-центр
      </Link>
      <Footer />
    </div>
  );
};

export default Page27092023;
