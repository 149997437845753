import React from "react";

const AboutL = () => {
  return (
    <div className="md:grid md:grid-cols-12 md:gap-4 mb-16 flex flex-col ">
      <div className="col-span-5 flex flex-col items-center  md:items-start">
        <div className="text-zinc-800 text-xl md:text-2xl font-bold leading-tight md:leading-loose">
          Международная программа <br />
          реализации инициатив <br />
          по защите традиционных <br />
          семейных ценностей <br />
        </div>
        <div className="text-red text-4xl sm:text-5xl md:text-6xl md:mt-0 mt-5 font-bold leading-tight">
          «Семья и мир»
        </div>
        <img src="/oprogramme.png" alt="bear" className="mt-10" />
      </div>
      <div className="col-span-7 text-black text-lg font-medium leading-normal">
        <p className="mb-4">
          Международная программа реализации инициатив по защите традиционных
          семейных ценностей «Семья и мир» - это соборная международная
          инициатива по реализации политики поддержки и популяризации
          естественной семьи, традиционных духовно - нравственных ценностей, а
          также защиты права на жизнь.
        </p>
        <p className="mb-4">
          Актуальность программы заключается в проблеме мирового кризиса семьи в
          современном обществе.
        </p>
        <p className="mb-4">
          Миссия программы: Объединение мирового сообщества ради защиты и
          продвижения традиционных семейных ценностей.
        </p>
        <p className="mb-4">
          Программа должна стать механизмом последовательно реализуемой
          международной политики объединения консервативных сил в эпоху
          насаждения античеловеческих ценностей, способствовать выработке
          решений актуальных проблем, с которыми сталкиваются государства и
          традиционная мировая общественность. Стать символом и механизмом
          объединения мирового сообщества в целях налаживания международного
          диалога по актуальным вопросам идеологической, информационной и
          духовной безопасности, а также сохранения, укрепления и продвижения
          традиционных духовно - нравственных ценностей, через проводимые в
          рамках Программы мероприятия.
        </p>
        <p className="mb-4">
          Реализуемые мероприятия Программы содействуют сохранению, укреплению и
          продвижению традиционных духовно - нравственных и семейных ценностей и
          их передаче от поколения к поколению, противодействию распространению
          деструктивной идеологии, формированию и продвижению понимания в
          мировом сознании важной роли милосердного служения ближнему в том
          числе роли женского служения как исторически сложившегося примера веры
          и материнской любви.
        </p>
      </div>
    </div>
  );
};

export default AboutL;
