import React from "react";
import Footer from "../Footer";
import { Link } from "react-router-dom";

const Page13062023 = () => {
  return (
    <div className="flex flex-col justify-start items-start gap-[40px] sm:gap-[90px] ">
      <div className="w-full sm:w-5/6 flex flex-col justify-start items-start gap-[20px] sm:gap-[40px] ">
        <div className=" text-black text-2xl sm:text-5xl font-bold font-golos leading-snug mt-24 sm:mt-40 ">
          В Дамаске обсудили вопросы укрепления и защиты традиционных семейных
          ценностей
        </div>
        <div className="text-neutral-950 text-[18px] sm:text-[27px] font-normal sm:font-semibold font-golos leading-loose ">
          13 июня 2023 года
        </div>
        <div className="text-black text-lg font-golos font-normal leading-normal flex flex-col gap-6">
          <p>
            {" "}
            5 июня, в день Святого Духа, по благословению Блаженнейшего
            Патриарха Великой Антиохии и всего Востока Иоанна X Божественную
            литургию в храме святого священномученика Игнатия Богоносца в
            Дамаске возглавил викарный епископ Селевкийский Роман в сослужении
            архимандрита Филиппа.
          </p>
          <p>
            За богослужением молились члены Международной общественной
            организации «Союз православных женщин», паломники из Ливана и члены
            русскоязычной общины Сирии. Кроме того, благодаря материальной
            поддержке МОО «Союз православных женщин» на совместную молитву
            смогли приехать 50 женщин и детей из разных удаленных уголков Сирии.
          </p>
          <p>
            Сопредседатель Международной общественной организации «Союз
            православных женщин» Дмитриевская Наталья Вильевна преподнесла
            епископу Роману памятный подарок и произнесла приветственные слова
            от лица организации.
          </p>
          <div className="grid grid-rows-12 sm:grid-rows-6 grid-flow-col grid-cols-1 sm:grid-cols-2 gap-4">
            <img
              src="/damask/1.jpg"
              className="object-cover h-[300px] max-w-[300px] rounded-lg"
            ></img>
            <img
              src="/damask/2.jpg"
              className="object-cover h-[300px] max-w-[300px] rounded-lg"
            ></img>
            <img
              src="/damask/3.jpg"
              className="object-cover h-[300px] max-w-[300px] rounded-lg"
            ></img>
            <img
              src="/damask/4.jpg"
              className="object-cover h-[300px] max-w-[300px] rounded-lg"
            ></img>
            <img
              src="/damask/5.jpg"
              className="object-cover h-[300px] max-w-[300px] rounded-lg"
            ></img>
            <img
              src="/damask/6.jpg"
              className="object-cover h-[300px] max-w-[300px] rounded-lg"
            ></img>
            <img
              src="/damask/7.jpg"
              className="object-cover h-[300px] max-w-[300px] rounded-lg"
            ></img>
            <img
              src="/damask/8.jpg"
              className="object-cover h-[300px] max-w-[300px] rounded-lg"
            ></img>
            <img
              src="/damask/9.jpg"
              className="object-cover h-[300px] max-w-[300px] rounded-lg"
            ></img>
            <img
              src="/damask/10.jpg"
              className="object-cover h-[300px] max-w-[300px] rounded-lg"
            ></img>
            <img
              src="/damask/11.jpg"
              className="object-cover h-[300px] max-w-[300px] rounded-lg"
            ></img>
            <img
              src="/damask/12.jpg"
              className="object-cover h-[300px] max-w-[300px] rounded-lg"
            ></img>
          </div>
          <p>
            После окончания Божественной литургии в приходском зале
            Представительства Русской Православной Церкви в Дамаске прошёл
            круглый стол «Троицкие духовные беседы», организованный
            Международной общественной организацией «Союз православных женщин» в
            рамках международной программы реализации инициатив по защите
            традиционных семейных ценностей «Семья и мир».
          </p>
          <p>
            Модераторами беседы выступили Представительство Русской Православной
            Церкви в Дамаске и Международная общественная организация «Союз
            православных женщин».
          </p>
          <p>
            В ходе мероприятия состоялось обсуждение вопросов укрепления и
            защиты традиционных семейных ценностей как наиболее важного ядра
            сохранения общества.
          </p>
          <p>
            Перед началом заседания с приветственным словом к участникам
            обратился епископ Селевкийский Роман, обозначив важность поднимаемых
            на встрече вопросов защиты семьи и детей. С видеообращением к гостям
            мероприятия выступила Заместитель Председателя Государственной Думы
            Российской Федерации А.Ю.Кузнецова. Она пожелала участникам встречи
            успехов и поделилась радостным событием, связанным с возвращением в
            храм иконы Троицы Андрея Рублёва.
          </p>
          <p>
            Также с докладами выступили: представитель Патриарха Московского и
            всея Руси при Антиохийском Патриаршем престоле архимандрит Филипп
            (Васильцев), сопредседатель МОО «Союз православных женщин»
            Дмитриевская Наталья Вильевна, член Правления МОО «Союз православных
            женщин» Сипягина Елена Александровна, представитель православной
            общины в Ливане Эль-Хури Анна Михайловна.
          </p>
          <p>
            С российской стороны участниками мероприятия стали сопредседатель
            международной общественной организации «Союз православных женщин»
            Н.В.Дмитриевская, выступившая с приветствием к участникам круглого
            стола, руководитель Российского центра науки и культуры Николай
            Сухов и начальник управления общественной дипломатии Екатерина
            Торубарова.
          </p>
          <p>
            В мероприятии приняли участие не только православные женщины, но и
            многочисленные гости разных конфессий: заместитель ректора
            университета Биляд Аль Шам по науке Шейх Абд Аль Салям Ражех,
            председатель попечительского совета фонда развития «Houna Hawyati»
            Мария Саади, председатель сирийской комиссии по делам семьи и
            населения Самар Аль Сибаи, конфессия мусульман друзов в Сирии Шейх
            Баян Зед Скекер, член парламента Руба Мирза, декан факультета
            шариата университета Дамаска, советник министра по делам вакуфов
            Мухаммад Хасан Авад и председатель совета директоров медицинского
            благотворительного фонда «Масана» Раша Харфуш.
          </p>
          <p>
            По окончании круглого стола участники посетили
            Реабилитационно-физиотерапевтический центр, открывшийся три года
            назад на территории представительства Русской Православной Церкви в
            Сирии. Маленькие пациенты подготовили для гостей небольшой концерт,
            были исполнены песни не только на сирийском, но и русском языках.
            Директор центра доктор Хасан Насралла провел для гостей экскурсию и
            рассказал о специфике и методиках работы врачей, количестве
            пациентов и успехах малышей, которые получили помощь.
          </p>
          <p>
            Также с докладами выступили: представитель Патриарха Московского и
            всея Руси при Антиохийском Патриаршем престоле архимандрит Филипп
            (Васильцев), сопредседатель МОО «Союз православных женщин»
            Дмитриевская Наталья Вильевна, член Правления МОО «Союз православных
            женщин» Сипягина Елена Александровна, представитель православной
            общины в Ливане Эль-Хури Анна Михайловна.
          </p>
          <p className=" text-red">Представительство РПЦ в Дамаске</p>
        </div>
      </div>
      <Link
        to={"/about?tab=presscenter"}
        className="mb-[40px] px-[38px] py-[11px] xs:px-11 xs:py-[16px] text-base leading-tight shadow-[-1px_1px_11px_6px_rgba(212,18,22,0.24)]   font-semibold border-gradient-rainred rounded-[15px] xs:rounded-[24px] justify-center  gap-2.5 inline-flex text-red xs:text-[24px] xs:font-bold xs:leading-7  transition ease-in-out hover:-translate-y-1  hover:delay-50 duration-100"
      >
        Вернуться в пресс-центр
      </Link>
      <Footer />
    </div>
  );
};

export default Page13062023;
