import React from "react";
import Footer from "../Footer";
import { Link } from "react-router-dom";

const Page19042024 = () => {
  return (
    <div className="flex flex-col justify-start items-start gap-[40px] sm:gap-[90px] ">
      <div className="w-full sm:w-5/6 flex flex-col justify-start items-start gap-[20px] sm:gap-[40px] ">
        <div className=" text-black text-2xl sm:text-5xl font-bold font-golos leading-snug mt-24 sm:mt-40 ">
          Концерт &laquo;Мирное небо для детской улыбки&raquo; пройдёт
          в&nbsp;Сербии
        </div>
        <div className="text-neutral-950 text-[18px] sm:text-[27px] font-normal sm:font-semibold font-golos leading-loose ">
          19 апреля 2024 года
        </div>
        <div className="text-black text-lg font-golos font-normal leading-normal flex flex-col gap-6">
          <p>
            {" "}
            19&nbsp;апреля в&nbsp;Русском доме в&nbsp;Белграде в&nbsp;четвёртый
            раз пройдёт благотворительный концерт &laquo;Мирное небо для детской
            улыбки&raquo;. Там соберутся все, кому небезразличны судьбы детей,
            нуждающихся в&nbsp;поддержке и&nbsp;помощи.
          </p>
          <p>
            В&nbsp;2022 году два благотворительных концерта собрали большую
            сумму пожертвований. Благодаря этому Русская Гуманитарная Миссия
            (РГМ){" "}
            <a href="https://t.me/rgmagency/959" className="underline text-red">
              закупила
            </a>{" "}
            инкубатор для выхаживания детей и&nbsp;передала его Центру матери
            и&nbsp;ребенка в&nbsp;Горловке. В&nbsp;этот раз средства пойдут
            на&nbsp;помощь детям Косово и&nbsp;Метохии.
          </p>
          <p>
            Событие пройдёт при поддержке Русского дома в&nbsp;Белграде,
            посольства России в&nbsp;Сербии, Международного фонда духовного
            единства народов, РГМ, Координационного совета российских
            соотечественников в&nbsp;Сербии и&nbsp;фирмы &laquo;MBR
            Security&raquo;.
          </p>
          <p>
            Приходите и&nbsp;проведите вечер в&nbsp;дружеской атмосфере, где
            каждый может принять участие и&nbsp;оказать помощь.
          </p>

          <img src="/mic.jpg"></img>
          <p>
            Источник:{" "}
            <a
              href="https://rs.gov.ru/news/russkij-dom-v-aleksandrii-peredal-gumanitarnuyu-pomoshh-detyam/"
              className="underline text-red"
            >
              Россотрудичество
            </a>{" "}
          </p>
        </div>
      </div>
      <Link
        to={"/about?tab=presscenter"}
        className="mb-[40px] px-[38px] py-[11px] xs:px-11 xs:py-[16px] text-base leading-tight shadow-[-1px_1px_11px_6px_rgba(212,18,22,0.24)]   font-semibold border-gradient-rainred rounded-[15px] xs:rounded-[24px] justify-center  gap-2.5 inline-flex text-red xs:text-[24px] xs:font-bold xs:leading-7  transition ease-in-out hover:-translate-y-1  hover:delay-50 duration-100"
      >
        Вернуться в пресс-центр
      </Link>
      <Footer />
    </div>
  );
};

export default Page19042024;
