import React, { useEffect, useState } from "react";
import Footer from "../Footer";
import { Tilt } from "react-tilt";

// const tilesData = [
//   {
//     label: "Фонд помощи детям-сиротам",
//     image: "https://via.placeholder.com/385x310",
//     description:
//       "Рыбатекст используется дизайнерами, проектировщиками и фронтендерами",
//   },
//   {
//     label: "Конкурс студенческих работ",
//     image: "https://via.placeholder.com/385x310",
//     description:
//       "Рыбатекст используется дизайнерами, проектировщиками и фронтендерами",
//   },
//   {
//     label: "Новая библиотека книг по программированию",
//     image: "https://via.placeholder.com/385x310",
//     description:
//       "Рыбатекст используется дизайнерами, проектировщиками и фронтендерами ",
//   },
//   {
//     label: "Новый курс по дизайну",
//     image: "https://via.placeholder.com/385x310",
//     description:
//       "Рыбатекст используется дизайнерами, проектировщиками и фронтендерами ",
//   },
//   {
//     label: "Конкурс студенческих работ",
//     image: "https://via.placeholder.com/385x310",
//     description:
//       "Рыбатекст используется дизайнерами, проектировщиками и фронтендерами ",
//   },
//   {
//     label: "Новая библиотека книг по программированию",
//     image: "https://via.placeholder.com/385x310",
//     description:
//       "Рыбатекст используется дизайнерами, проектировщиками и фронтендерами ",
//   },
// ];
export default function Serbia() {
  const [maxBlocksPerRow, setMaxBlocksPerRow] = useState(3);
  const [maxBlockWidth, setMaxBlockWidth] = useState(32);

  useEffect(() => {
    function handleResize() {
      let width = window.innerWidth;
      if (width < 768) {
        setMaxBlocksPerRow(1);
        setMaxBlockWidth(100);
      } else if (width < 1024) {
        setMaxBlocksPerRow(2);
        setMaxBlockWidth(47);
      } else {
        setMaxBlocksPerRow(3);
        setMaxBlockWidth(32);
      }
    }

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="flex flex-col gap-10 items-center justify-center h-screen sm:items-start sm:justify-start ">
      <div className="flex flex-row gap-2 items-center justify-center mt-24 sm:mt-40">
        <img src="/rs.png" alt="rs" />
        <div className="text-red text-3xl md:text-6xl font-bold font-golos  ">
          Республика Сербия
        </div>
      </div>
      <div className="text-black text-lg font-semibold font-golos leading-normal"></div>
      {/* <div className="w-full flex flex-wrap gap-5">
        {tilesData.map(
          (tile, index) =>
            tile && (
              <div
                // Изменение здесь
                className={`max-h-[700px] px-2.5 pt-2.5 pb-5 bg-white rounded-2xl shadow-[4px_4px_21px_3px_rgba(47,47,47,0.15);] flex-col justify-center items-start gap-5 inline-flex ${
                  index % maxBlocksPerRow === maxBlocksPerRow - 1
                    ? "w-full"
                    : `w-1/${maxBlocksPerRow}`
                }`}
                style={{ maxWidth: `${maxBlockWidth}%` }}
              >
                <img src={tile.image} className="rounded-xl w-full" />

                <h3 className="w-52 opacity-75 text-black text-xs font-normal font-golos leading-none">
                  {tile.label}
                </h3>

                <p className="w-full text-zinc-800 text-2xl font-bold font-golos leading-7">
                  {tile.description}
                </p>
              </div>
            )
        )}
      </div> */}
      <Footer />
    </div>
  );
}
