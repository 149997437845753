import React from "react";
import Footer from "../Footer";
import { Link } from "react-router-dom";

const Page14062023 = () => {
  return (
    <div className="flex flex-col justify-start items-start gap-[40px] sm:gap-[90px] ">
      <div className="w-full sm:w-5/6 flex flex-col justify-start items-start gap-[20px] sm:gap-[40px] ">
        <div className=" text-black text-2xl sm:text-5xl font-bold font-golos leading-snug mt-24 sm:mt-40 ">
          Круглый стол «Насущные проблемы Русского мира и пути их преодоления —
          взгляд из Армении»
        </div>
        <div className="text-neutral-950 text-[18px] sm:text-[27px] font-normal sm:font-semibold font-golos leading-loose ">
          14 июня 2023 года
        </div>
        <div className="text-black text-lg font-golos font-normal leading-normal flex flex-col gap-6">
          <p>
            14 июня в гостеприимном Русском Доме в Ереване прошел круглый стол
            «Насущные проблемы Русского мира и пути их преодоления — взгляд из
            Армении».
          </p>
          <p>В работе круглого стола приняли участие:</p>
          <p>
            — Дмитриевская Наталья Вильевна, сопредседатель МОО «Союз
            православных женщин»;
            <br /> — Дерипаско Виктория Михайловна, заместитель руководителя
            Россотрудничества в Армении; <br />— Саакян Наталья Анатольевна,
            начальник отдела работы с соотечественниками Русского Дома в
            Ереване;
            <br /> — протоиерей Арсений Григорянц, Благочинный Патриарших
            приходов в Армении, руководитель БОО «Русский Народный Собор» в
            Армении, кандидат физ.мат. наук, доцент;
            <br /> — Григорянц Анаит Ервандовна, директор филиала МОО «Союз
            православных женщин» в Республике Армения;
            <br /> — Аракелова Виктория Александровна, кандидат исторических
            наук, профессор;
            <br /> — Сирунян Тамара Александровна, директор Фонда Карена
            Демирчяна;
            <br /> — Арутюнян Аида Сергеевна, президент МЦРК «Гармония»;
            <br /> — Осипова Инна Валерьевна, кандидат педагогических наук;
            <br /> — Папоян Татьяна Чарпиковна, председатель ОО «ОДА-Луч»;
            <br />
            <br /> — Мисакян Лилит Максимовна, врач-гинеколог;
            <br /> — Саркисян Аршак Геворкович, заслуженный профессор
            Российско-Армянского университета, доцент кафедры русского языка и
            профессиональной коммуникации РАУ, исполнительный секретарь
            Армянской ассоциации русистов, к.ф.н.
          </p>
          <div className="grid sm:grid-rows-2 grid-rows-4 grid-flow-col grid-cols-1 sm:grid-cols-2 gap-4">
            <img
              src="/erevan/1.jpg"
              alt="one"
              className="object-cover h-[300px] max-w-[300px] rounded-lg"
            />
            <img
              src="/erevan/2.jpg"
              alt="two"
              className="object-cover h-[300px] max-w-[300px] rounded-lg"
            />
            <img
              src="/erevan/3.jpg"
              alt="three"
              className="object-cover h-[300px] max-w-[300px] rounded-lg"
            />
            <img
              src="/erevan/4.jpg"
              alt="four"
              className="object-cover h-[300px] max-w-[300px]  rounded-lg"
            />
          </div>
          <p>
            Со словами приветствия к собравшимся обратились Дерипаско Виктория
            Михайловна и Дмитриевская Наталья Вильевна.
          </p>
          <p>
            Модератором круглого стола выступила Григорянц Анаит Ервандовна.
          </p>
          <p>Перед собравшимися выступили:</p>
          <p>
            1. Аракелова Виктория Александровна с докладом на тему: «Россия и
            Армения: старые вызовы в новом мироустройстве».
            <br />
            2. Протоиерей Арсений Григорянц — «Семья как основа Русского мира».
            <br />
            3. Мисакян Лилит Максимовна с докладом на противоабортную тему «Не
            навреди…».
          </p>
          <p>
            Также с докладами выступили: представитель Патриарха Московского и
            всея Руси при Антиохийском Патриаршем престоле архимандрит Филипп
            (Васильцев), сопредседатель МОО «Союз православных женщин»
            Дмитриевская Наталья Вильевна, член Правления МОО «Союз православных
            женщин» Сипягина Елена Александровна, представитель православной
            общины в Ливане Эль-Хури Анна Михайловна.
          </p>
          <p>
            Участники заседания высоко оценили опыт работы врача-гинеколога
            Лилит Максимовны Мисакян и предложили максимально широко
            распространить положительный опыт по противоабортной деятельности
            разработанной специалистом.
          </p>
          <p>
            Участники круглого стола выступили с предложением начать широкое
            обсуждение изменений в законодательстве РФ.
          </p>
          <p>
            Основная идея изменений: семья должна стать полноценным субъектом
            права. Необходимо содействовать пониманию того, что школа и семья
            должны поддерживать друг друга, что школа должна быть просемейной. И
            семья, в свою очередь, должна поддерживать авторитет Учителя.
          </p>
          <p>
            Во всех без исключения докладах были подняты важные и животрепещущие
            темы.
          </p>
          <p>
            В заключение Наталья Вильевна Дмитриевская поблагодарила собравшихся
            за плодотворную и интересную дискуссию.
          </p>
          <p>По итогам круглого стола была принята резолюция.</p>
          <p className=" text-red">
            Пресс-служба МОО «Союз православных женщин»
          </p>
        </div>
      </div>
      <Link
        to={"/about?tab=presscenter"}
        className="mb-[40px] px-[38px] py-[11px] xs:px-11 xs:py-[16px] text-base leading-tight shadow-[-1px_1px_11px_6px_rgba(212,18,22,0.24)]   font-semibold border-gradient-rainred rounded-[15px] xs:rounded-[24px] justify-center  gap-2.5 inline-flex text-red xs:text-[24px] xs:font-bold xs:leading-7  transition ease-in-out hover:-translate-y-1  hover:delay-50 duration-100"
      >
        Вернуться в пресс-центр
      </Link>
      <Footer />
    </div>
  );
};

export default Page14062023;
