import { useState, useEffect, useRef } from "react";
import TestEarth from "./SmComponents/TestEarth";
import Expert from "./SmComponents/Expert";
import AboutL from "./SmComponents/AboutL";
import { Tilt } from "react-tilt";
import { useLocation, useNavigate } from "react-router-dom";
// данные табов

const tabsData = [
  {
    id: "oprogramme",
    label: "О программе",
    additionalContent: <AboutL />,
    content: [
      {
        label: "3 мая 2024 года",
        image: "/prer.jpg",
        description:
          "В Дании подростки с 15 лет смогут делать аборт без согласия родителей",
        url: "/page03052024",
      },
      {
        label: "4 сентября 2023 года",
        image: "/pic1.jpg",
        description:
          "Православный кинолекторий пройдёт в Минске (Республика Беларусь)",
        url: "/page04042023",
      },
      {
        label: "5 мая 2023 года",
        image: "/placeholder.png",
        description:
          "В Айя-Напе прошел праздник, посвященный традиционным семейным ценностям ",
        url: "/page05052023",
      },
      {
        label: "8 июля 2023 года",
        image: "/pic2.jpg",
        description:
          "В Никосии прошел митинг в защиту традиционных семейных ценностей",
        url: "/page08072023",
      },

      {
        label: "10 июля 2023 года",
        image: "/pic4.jpg",
        description:
          "В Русском доме состоялась встреча Молодежного клуба, посвященная Всероссийскому празднику «День семьи, любви и верности ",
        url: "/page10072023",
      },
      {
        label: "10 июля 2023 года",
        image: "/pic7.jpg",
        description: "Состоялся вебинар «Право на жизнь» ",
        url: "/page11072023",
      },
      {
        label: "21 июля 2023 года",
        image: "/pic8.jpg",
        description:
          "Вице-спикер Госдумы Анна Кузнецова назвала важнейшие законы по сохранению традиционных ценностей",
        url: "/page21072023",
      },
      {
        label: "27 сентября 2023 года",
        image: "/dobro/1.png",
        description:
          "В провинции Бехейра волонтеры программы «Миссия Добро» провели заключительный мастер-класс для детей и подростков",
        url: "/page27092023",
      },
      {
        label: "14 июня 2023 года",
        image: "/erevan/1.jpg",
        description:
          "Круглый стол «Насущные проблемы Русского мира и пути их преодоления - взгляд из Армении»",
        url: "/page14062023",
      },
      {
        label: "16 мая 2023 года",
        image: "/forum/1.jpg",
        description:
          "Страны объединяются: запущена международная программа по защите семейных ценностей",
        url: "/page16052023",
      },
      {
        label: "13 июня 2023 года",
        image: "/damask/1.jpg",
        description:
          "В Дамаске обсудили вопросы укрепления и защиты традиционных семейных ценностей",
        url: "/page13062023",
      },
    ],
  },
  {
    id: "countries",
    label: "Страны — участницы",
    content: <TestEarth />,
  },
  {
    id: "expertsovet",
    label: "Экспертный совет",
    content: <Expert />,
  },
  {
    id: "presscenter",
    label: "Пресс-центр",
    content: [
      {
        label: "4 сентября 2023 года",
        image: "/pic1.jpg",
        description:
          "Православный кинолекторий пройдёт в Минске (Республика Беларусь)",
        url: "/page04042023",
      },
      {
        label: "5 мая 2023 года",
        image: "/placeholder.png",
        description:
          "В Айя-Напе прошел праздник, посвященный традиционным семейным ценностям ",
        url: "/page05052023",
      },
      {
        label: "8 июля 2023 года",
        image: "/pic2.jpg",
        description:
          "В Никосии прошел митинг в защиту традиционных семейных ценностей",
        url: "/page08072023",
      },

      {
        label: "10 июля 2023 года",
        image: "/pic4.jpg",
        description:
          "В Русском доме состоялась встреча Молодежного клуба, посвященная Всероссийскому празднику «День семьи, любви и верности ",
        url: "/page10072023",
      },
      {
        label: "10 июля 2023 года",
        image: "/pic7.jpg",
        description: "Состоялся вебинар «Право на жизнь» ",
        url: "/page11072023",
      },
      {
        label: "21 июля 2023 года",
        image: "/pic8.jpg",
        description:
          "Вице-спикер Госдумы Анна Кузнецова назвала важнейшие законы по сохранению традиционных ценностей",
        url: "/page21072023",
      },
      {
        label: "13 июня 2023 года",
        image: "/pic8.jpg",
        description:
          "В Дамаске обсудили вопросы укрепления и защиты традиционных семейных ценностей",
        url: "/page13062023",
      },
      {
        label: "27 сентября 2023 года",
        image: "/dobro/1.png",
        description:
          "В провинции Бехейра волонтеры программы «Миссия Добро» провели заключительный мастер-класс для детей и подростков",
        url: "/page27092023",
      },
      {
        label: "14 июня 2023 года",
        image: "/erevan/1.jpg",
        description:
          "Круглый стол «Насущные проблемы Русского мира и пути их преодоления - взгляд из Армении»",
        url: "/page14062023",
      },
      {
        label: "16 мая 2023 года",
        image: "/forum/1.jpg",
        description:
          "Страны объединяются: запущена международная программа по защите семейных ценностей",
        url: "/page16052023",
      },
      {
        label: "13 июня 2023 года",
        image: "/damask/1.jpg",
        description:
          "В Дамаске обсудили вопросы укрепления и защиты традиционных семейных ценностей",
        url: "/page13062023",
      },
    ],
  },
];

export function AboutTabs() {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);
  const [visibleBlocks, setVisibleBlocks] = useState(
    new Array(tabsData.length).fill(3)
  );
  const [maxBlocksPerRow, setMaxBlocksPerRow] = useState(3);
  const [maxBlockWidth, setMaxBlockWidth] = useState(30);
  const scrollTimeoutRef = useRef();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const tabQuery = query.get("tab");
  const tabsRef = useRef([]);
  const navigate = useNavigate();
  const tabsContainerRef = useRef(null);
  const updateTabIndicator = (index) => {
    const tab = tabsRef.current[index];
    if (tab) {
      const newLeft = tab.offsetLeft;
      const newWidth = tab.clientWidth;
      setTabUnderlineLeft(newLeft);
      setTabUnderlineWidth(newWidth);
    }
  };
  useEffect(() => {
    setVisibleBlocks(new Array(tabsData.length).fill(3));
  }, []);
  useEffect(() => {
    if (tabQuery) {
      // Ищем индекс таба по id, который установлен в tabQuery
      const tabIndex = tabsData.findIndex((tab) => tab.id === tabQuery);
      // Если таб найден, устанавливаем его как активный
      if (tabIndex !== -1) {
        setActiveTabIndex(tabIndex);
      }
    }
  }, [tabQuery]);
  useEffect(() => {
    function handleScroll() {
      const scrollLeft = tabsContainerRef.current.scrollLeft;
      const currentTab = tabsRef.current[activeTabIndex];
      if (currentTab) {
        const maxLeft = scrollLeft;
        const maxRight = scrollLeft + tabsContainerRef.current.clientWidth;
        let newLeft = currentTab.offsetLeft;
        let newWidth = currentTab.clientWidth;

        if (newLeft < maxLeft) {
          // Если таб частично скрыт слева
          newWidth = newWidth - (maxLeft - newLeft);
          newLeft = maxLeft;
        } else if (newLeft + newWidth > maxRight) {
          // Если таб частично скрыт справа
          newWidth = maxRight - newLeft;
        }

        setTabUnderlineLeft(newLeft - scrollLeft);
        setTabUnderlineWidth(newWidth);
      }
    }

    tabsContainerRef.current.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);

    return () => {
      if (tabsContainerRef.current) {
        tabsContainerRef.current.removeEventListener("scroll", handleScroll);
      }
      window.removeEventListener("resize", handleScroll);
    };
  }, [activeTabIndex]);

  const handleTabClick = (index) => {
    setActiveTabIndex(index);

    updateTabIndicator(index);
    const currentTab = tabsRef.current[index];
    if (currentTab) {
      const maxLeft = tabsContainerRef.current.scrollLeft;
      const maxRight = maxLeft + tabsContainerRef.current.clientWidth;
      let newLeft = currentTab.offsetLeft;
      let newWidth = currentTab.clientWidth;

      if (newLeft < maxLeft) {
        newWidth = newWidth - (maxLeft - newLeft);
        newLeft = maxLeft;
      } else if (newLeft + newWidth > maxRight) {
        newWidth = maxRight - newLeft;
      }

      setTabUnderlineLeft(newLeft - maxLeft);
      setTabUnderlineWidth(newWidth);
    }
  };
  useEffect(() => {
    function setTabPosition() {
      const currentTab = tabsRef.current[activeTabIndex];
      setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
      setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
    }

    setTabPosition();
    window.addEventListener("resize", setTabPosition);

    return () => window.removeEventListener("resize", setTabPosition);
  }, [activeTabIndex]);
  useEffect(() => {
    function setTabPosition() {
      const currentTab = tabsRef.current[activeTabIndex];
      setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
      setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
    }

    setTabPosition();
    window.addEventListener("resize", setTabPosition);

    return () => window.removeEventListener("resize", setTabPosition);
  }, [activeTabIndex]);

  useEffect(() => {
    const handleResize = () => {
      let width = window.innerWidth;

      if (width < 768) {
        setMaxBlocksPerRow(1);
        setMaxBlockWidth(100);
      } else if (width < 1024) {
        setMaxBlocksPerRow(2);
        setMaxBlockWidth(47);
      } else {
        setMaxBlocksPerRow(3);
        setMaxBlockWidth(32);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    setVisibleBlocks((prevVisibleBlocks) => {
      const updatedVisibleBlocks = [...prevVisibleBlocks];
      const currentContent = tabsData[activeTabIndex].content;
      updatedVisibleBlocks[activeTabIndex] = Array.isArray(currentContent)
        ? Math.min(activeTabIndex === 3 ? 12 : 3, currentContent.length)
        : 0; // Или какое-то другое значение для тех вкладок, где нет массива
      return updatedVisibleBlocks;
    });
  }, [activeTabIndex]);

  const handleLoadMore = () => {
    setVisibleBlocks((prevVisibleBlocks) => {
      const updatedVisibleBlocks = [...prevVisibleBlocks]; // Копирование предыдущего состояния
      const incrementValue = activeTabIndex === 3 ? 12 : 3; // Устанавливаем шаг приращения
      updatedVisibleBlocks[activeTabIndex] = Math.min(
        updatedVisibleBlocks[activeTabIndex] + incrementValue,
        tabsData[activeTabIndex].content.length
      );
      return updatedVisibleBlocks; // Возвращаем обновленное состояние
    });
  };

  return (
    <div
      className="w-full flex flex-col items-center md:items-start justify-center"
      style={{ position: "relative" }}
    >
      <div className=" text-red text-4xl md:text-5xl font-bold font-golos leading-[72px] mt-28">
        О программе
      </div>
      <div className="relative w-full mb-[40px] mt-[40px]">
        <div
          ref={tabsContainerRef}
          className="tabsContainer flex space-x-10 md:space-x-20 border-b border-b-1 border-neutral-900/50 w-full overflow-x-auto"
        >
          {tabsData.map((tab, idx) => {
            return (
              <button
                key={idx}
                ref={(el) => (tabsRef.current[idx] = el)}
                className={`pt-2 pb-3 font-bold leading-loose text-md sm:text-lg md:text-[27px] whitespace-nowrap ${
                  activeTabIndex === idx
                    ? "text-neutral-800"
                    : "text-neutral-800/50"
                }`}
                onClick={() => handleTabClick(idx)}
              >
                {tab.label}
              </button>
            );
          })}
        </div>
        <span
          className="tab-highlight absolute bottom-[-3px] block  h-2 rounded-full bg-red transition-all duration-300"
          style={{
            left: tabUnderlineLeft,
            width: tabUnderlineWidth,
            overflowX: "hidden",
          }}
        />
      </div>
      <div className="w-full items-center justify-center flex flex-wrap ">
        <div className="w-full py-4 text-lg font-semibold justify-center text-white leading-normal flex flex-wrap gap-5 ">
          {Array.isArray(tabsData[activeTabIndex].content) ? (
            <>
              {tabsData[activeTabIndex].additionalContent}
              {tabsData[activeTabIndex].content
                .slice(0, visibleBlocks[activeTabIndex] || 0)
                .map(
                  (item, index) =>
                    item && (
                      <div
                        key={`tab-${activeTabIndex}-block-${item.id || index}`}
                        // Изменение здесь
                        className={`zoomEffect max-h-[700px] px-2.5 pt-2.5 pb-5 bg-white rounded-2xl shadow-[4px_4px_21px_3px_rgba(47,47,47,0.15);] flex-col justify-start items-start gap-5 inline-flex ${
                          index % maxBlocksPerRow === maxBlocksPerRow - 1
                            ? "w-full"
                            : `w-1/${maxBlocksPerRow}`
                        }`}
                        style={{ maxWidth: `${maxBlockWidth}%` }}
                      >
                        <img
                          src={item.image}
                          className="rounded-xl w-full max-h-[310px] object-cover"
                          onClick={() => navigate(item.url)}
                        />
                        <div className="w-52 opacity-75 text-black text-xs font-normal font-golos leading-none">
                          {item.label}
                        </div>
                        <div className="w-full text-zinc-800 text-2xl font-bold font-golos leading-7">
                          {item.description}
                        </div>
                      </div>
                    )
                )}
            </>
          ) : (
            tabsData[activeTabIndex].additionalContent ||
            tabsData[activeTabIndex].content
          )}
        </div>
        <div className="flex justify-center items-center mt-5">
          {tabsData[activeTabIndex].content.length >
            visibleBlocks[activeTabIndex] && (
            <button
              className="mb-[40px] px-[38px] py-[11px] xs:px-11 xs:py-[16px] text-base leading-tight   font-semibold bg-gradient-rainred rounded-[15px] xs:rounded-[24px] justify-center  gap-2.5 inline-flex text-neutral-100 xs:text-[24px] xs:font-semibold xs:leading-7  transition ease-in-out hover:-translate-y-1 hover:bg-gradient-rainred-light hover:delay-50 duration-100"
              onClick={handleLoadMore}
            >
              <p className="mb-[3px]">Загрузить ещё...</p>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
