import React from "react";
import Footer from "../Footer";
import { Link } from "react-router-dom";

const Svet = () => {
  return (
    <div className="flex flex-col justify-start items-start gap-[40px] sm:gap-[90px] ">
      <div className="w-full sm:w-5/6 flex flex-col justify-start items-start gap-[20px] sm:gap-[40px] ">
        <div className=" text-black text-2xl sm:text-5xl font-bold font-golos leading-snug mt-24 sm:mt-40 ">
          Подводит свои итоги Международный конкурс исследовательских и
          творческих литературных работ на русском языке «Духовный свет»
        </div>
        <div className="text-neutral-950 text-[18px] sm:text-[27px] font-normal sm:font-semibold font-golos leading-loose ">
          Весна 2024
        </div>
        <div className="text-black text-lg font-golos font-normal leading-normal flex flex-col gap-6">
          <img src="/Svet.jpg"></img>
          <p>
            {" "}
            Организаторами и партнерами конкурса являются Институт теологии
            Белорусского Государственного Университета, Белорусская Православная
            церковь, Всероссийская общественная организация «Российский Клуб
            Православных Меценатов», Международная общественная организация
            «Союз Православных женщин», Ассоциация «Российское историческое
            общество» и фонд «История Отечества»; Международный общественный
            фонд «Российский фонд мира», Общественная Палата города Твери
            Российской Федерации и другие организации.
          </p>

          <p>
            В этом году в конкурсе приняли участие учащиеся из Республики
            Беларусь, Российской Федерации, Армении и Кыргызстана.
          </p>

          <p>
            Торжественные мероприятия, посвященные награждению финалистов
            Международного этапа конкурса, пройдут в столице Беларуси – городе
            Минске 19 апреля 2024 года. На него приедут победители конкурса из
            разных стран. Тогда же пройдет и награждение дипломантов
            Национального этапа конкурса в Республике Беларусь.
          </p>

          <p>
            В рамках торжественных мероприятий учащиеся с родителями и педагоги
            посетят Церемонию награждения победителей, Церковно-исторический
            музей Белорусской Православной Церкви, крипту Национального
            мемориального комплекса «Храм-Памятник в честь Всех Святых и в
            память о жертвах, спасению Отечества нашего послуживших»,
            Национальный исторический музей и Национальный художественный музей
            Республики Беларусь, оранжерею Ботанического сада НАН РБ и другие
            достопримечательности белорусской столицы.
          </p>
        </div>
      </div>
      <Link
        to={"/about?tab=presscenter"}
        className="mb-[40px] px-[38px] py-[11px] xs:px-11 xs:py-[16px] text-base leading-tight shadow-[-1px_1px_11px_6px_rgba(212,18,22,0.24)]   font-semibold border-gradient-rainred rounded-[15px] xs:rounded-[24px] justify-center  gap-2.5 inline-flex text-red xs:text-[24px] xs:font-bold xs:leading-7  transition ease-in-out hover:-translate-y-1  hover:delay-50 duration-100"
      >
        Вернуться в пресс-центр
      </Link>
      <Footer />
    </div>
  );
};

export default Svet;
