import React from "react";
import Footer from "../Footer";
import { Link } from "react-router-dom";

const Page03052024 = () => {
  return (
    <div className="flex flex-col justify-start items-start gap-[40px] sm:gap-[90px] ">
      <div className="w-full sm:w-5/6 flex flex-col justify-start items-start gap-[20px] sm:gap-[40px] ">
        <div className=" text-black text-2xl sm:text-5xl font-bold font-golos leading-snug mt-24 sm:mt-40 ">
          В Дании подростки с 15 лет смогут делать аборт без согласия родителей
        </div>
        <div className="text-neutral-950 text-[18px] sm:text-[27px] font-normal sm:font-semibold font-golos leading-loose ">
          3 мая 2024 года
        </div>
        <div className="text-black text-lg font-golos font-normal leading-normal flex flex-col gap-6">
          <img src="/prer.jpg"></img>
          <p>
            {" "}
            Правительство Дании намерено разрешить подросткам с 15 лет делать
            аборт без согласия родителей или разрешения властей. Об этом 3 мая
            сообщило агентство Associated Press со ссылкой на Министерство
            здравоохранения королевства.
          </p>
          <p>
            «Правительство Дании сделает законным прерывание беременности
            женщинами до 18-й недели… Подросткам в возрасте от 15 до 17 лет
            разрешать делать аборт без согласия родителей», — говорится в
            материале.
          </p>
          <p>
            Проект внесут в парламент на рассмотрение. В случае принятия
            законопроекта изменения вступят в силу 1 июня 2025 года.
          </p>
          <p>
            Отмечается, что это первый за последние 50 лет случай, когда
            правительство Дании ослабляет ограничения на аборты.
          </p>
        </div>
      </div>
      <Link
        to={"/about?tab=presscenter"}
        className="mb-[40px] px-[38px] py-[11px] xs:px-11 xs:py-[16px] text-base leading-tight shadow-[-1px_1px_11px_6px_rgba(212,18,22,0.24)]   font-semibold border-gradient-rainred rounded-[15px] xs:rounded-[24px] justify-center  gap-2.5 inline-flex text-red xs:text-[24px] xs:font-bold xs:leading-7  transition ease-in-out hover:-translate-y-1  hover:delay-50 duration-100"
      >
        Вернуться в пресс-центр
      </Link>
      <Footer />
    </div>
  );
};

export default Page03052024;
