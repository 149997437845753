import React from "react";
import Footer from "../Footer";
import { Link } from "react-router-dom";

const Page08072023 = () => {
  return (
    <div className="flex flex-col justify-start items-start gap-[40px] sm:gap-[90px] ">
      <div className="w-full sm:w-5/6 flex flex-col justify-start items-start gap-[20px] sm:gap-[40px] ">
        <div className=" text-black text-2xl sm:text-5xl font-bold font-golos leading-snug mt-24 sm:mt-40 ">
          В Никосии прошел митинг в защиту традиционных семейных ценностей
        </div>
        <div className="text-neutral-950 text-[18px] sm:text-[27px] font-normal sm:font-semibold font-golos leading-loose ">
          8 июля 2023 года
        </div>
        <div className="text-black text-lg font-golos font-normal leading-normal flex flex-col gap-6">
          <img src="/pic2.jpg"></img>
          <p>
            {" "}
            В Никосии прошел митинг в защиту традиционных ценностей в российский
            День семьи
          </p>
          <p>
            8 июля 2023 года в центре столицы Кипра, г. Никосия, прошёл марш и
            митинг в защиту традиционных семейных ценностей. Его организатором
            выступила партия «Активные граждане» при поддержке Координационного
            совета российских соотечественников Кипра.
          </p>
          <p>
            Акция прошла в День семьи, любви и верности. Участники акции несли
            флаги Кипра, Греции, России, в также флаги с триколор-эмблемой
            традиционной семьи «Папа, мама, я — счастливая семья», ставшие
            символом объединения граждан Кипра в защиту от западных
            посягательств на права семьи и детей. Люди скандировали: «Руки прочь
            от наших детей и образования!»
          </p>
          <p>
            Православные объединённые митингующие выступили против обязательного
            всестороннего сексуального просвещения, начиная с дошкольных
            учреждений и заканчивая старшими классами, частных и государственных
            школ. Парламент страны и президент одобрили этот документ, следуя
            рекомендациям ЕС. Закон не учитывает религиозные или какие-то иные
            убеждения и мнение родителей.
          </p>
          <img src="/pic3.jpg"></img>
          <p>
            «Россия — первая в мире, которая как государство борется за
            сохранение традиционных ценностей, основанных на доминирующей в
            стране религии — православной. Россия защитила право семьи в законе,
            она единственная из стран, кто бьется против сатанизма, за
            возможность иметь эти права всем странам во всем мире.» — заявил с
            трибуны представитель Молодёжного клуба российских соотечественников
            Кипра, Владимир Горин.
          </p>
          <p>
            «Мы не хотим и не допустим разрушения невинных детских душ ради
            неприемлемого, чуждого и далекого от кипрской морали воспитания,
            прикрывающегося якобы защитой детей от сексуальных домогательств»-
            заявил кипрский общественный деятель, представитель и член
            Координационного Совета Международного Движения Русофилов, Микис
            Филаниотис.
          </p>
          <p>
            <a
              href="https://mir2023.site/2023/07/10/v-stolicze-kipra-sotni-zhitelej-vyshli-na-marsh-v-zashhitu-tradiczionnoj-semi/ "
              className="underline text-red"
            >
              Ссылка на источник
            </a>
          </p>
          <a
            href="https://ria.ru/20230708/miting-1883021947.html "
            className="underline text-red"
          >
            Дополнительная ссылка
          </a>
        </div>
      </div>
      <Link
        to={"/about?tab=presscenter"}
        className="mb-[40px] px-[38px] py-[11px] xs:px-11 xs:py-[16px] text-base leading-tight shadow-[-1px_1px_11px_6px_rgba(212,18,22,0.24)]   font-semibold border-gradient-rainred rounded-[15px] xs:rounded-[24px] justify-center  gap-2.5 inline-flex text-red xs:text-[24px] xs:font-bold xs:leading-7  transition ease-in-out hover:-translate-y-1  hover:delay-50 duration-100"
      >
        Вернуться в пресс-центр
      </Link>
      <Footer />
    </div>
  );
};

export default Page08072023;
