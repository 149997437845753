import { TabsState } from "./News";
import Footer from "./Footer";
import React from "react";

const States = () => {
  return (
    <div className="">
      <TabsState />
      <Footer />
    </div>
  );
};

export default States;
