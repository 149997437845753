import React from "react";
import Footer from "../Footer";
import { Link } from "react-router-dom";

const Page16052023 = () => {
  return (
    <div className="flex flex-col justify-start items-start gap-[40px] sm:gap-[90px] ">
      <div className="w-full sm:w-5/6 flex flex-col justify-start items-start gap-[20px] sm:gap-[40px] ">
        <div className=" text-black text-2xl sm:text-5xl font-bold font-golos leading-snug mt-24 sm:mt-40 ">
          Страны объединяются: запущена международная программа по защите
          семейных ценностей
        </div>
        <div className="text-neutral-950 text-[18px] sm:text-[27px] font-normal sm:font-semibold font-golos leading-loose ">
          16 мая 2023 года
        </div>
        <div className="text-black text-lg font-golos font-normal leading-normal flex flex-col gap-6">
          <p>
            {" "}
            Крепкую дружную семью, основанную на любви и нравственности, сегодня
            рассматривают как основу государственной безопасности.
          </p>
          <p>
            15 мая в Минске прошёл международный круглый стол по продвижению
            традиционных семейных ценностей «Семья. Традиции. Милосердие». На
            нем была представлена международная программа реализации инициатив
            по защите традиционных семейных ценностей «Семья и мир».
          </p>
          <p>
            В кругом столе приняли участие представители России, Беларуси,
            Сербии, Узбекистана, Египта, Турции, Сирии, Ливана, Грузии, Армении,
            Казахстана. Это депутаты, общественники, священнослужители. Формат
            встречи очно-дистанционный. Был организован телемост Москва-Минск.
          </p>
          <div className="grid grid-rows-10 sm:grid-rows-5 grid-flow-col sm:grid-cols-2 grid-cols-1 gap-4">
            <img
              src="/forum/1.jpg"
              className="object-cover h-[300px] max-w-[300px] rounded-lg"
            ></img>
            <img
              src="/forum/2.jpg"
              className="object-cover h-[300px] max-w-[300px] rounded-lg"
            ></img>
            <img
              src="/forum/3.jpg"
              className="object-cover h-[300px] max-w-[300px] rounded-lg"
            ></img>
            <img
              src="/forum/4.jpg"
              className="object-cover h-[300px] max-w-[300px] rounded-lg"
            ></img>
            <img
              src="/forum/5.jpg"
              className="object-cover h-[300px] max-w-[300px] rounded-lg"
            ></img>
            <img
              src="/forum/6.jpg"
              className="object-cover h-[300px] max-w-[300px] rounded-lg"
            ></img>
            <img
              src="/forum/7.jpg"
              className="object-cover h-[300px] max-w-[300px] rounded-lg"
            ></img>
            <img
              src="/forum/8.jpg"
              className="object-cover h-[300px] max-w-[300px] rounded-lg"
            ></img>
            <img
              src="/forum/9.jpg"
              className="object-cover h-[300px] max-w-[300px] rounded-lg"
            ></img>
            <img
              src="/forum/10.jpg"
              className="object-cover h-[300px] max-w-[300px] rounded-lg"
            ></img>
          </div>
          <p>
            Организатором мероприятия выступила Международная общественная
            организация «Союз православных женщин» при поддержке Патриаршей
            Комиссии по вопросам семьи, материнства и детства Московского
            Патриархата, представительства общественной организации «Российский
            Клуб Православных Меценатов» и участии Белорусского союза женщин.
          </p>
          <p>
            С приветственным словом к участникам обратились: <br /> — Анна
            Юрьевна Кузнецова, заместитель председателя Государственной Думы
            Федерального Собрания Российской Федерации, сопредседатель
            Международной общественной организации «Союз православных женщин»;
            <br /> — Нина Борисовна Жукова, сопредседатель Международной
            общественной организации «Союз православных женщин»;
            <br /> — архимандрит Филипп (Васильцев), Представитель Патриарха
            Московского и всея Руси при Патриархе Великой Антиохии и всего
            Востока.
          </p>
          <p>
            В своём приветственном слове А. Ю. Кузнецова констатировала:
            <br /> — Запрос на защиту традиционных семейных ценностей в мире с
            каждым годом растет. 16 процентов европейцев от 14 до 20 лет относят
            себя к ЛГБТ. В некоторых странах пропаганда ЛГБТ приняла тотальный
            оборот — открываются детские сады, проводятся мастер-классы по смене
            пола, для детей организуют встречи с трансгендерами, возводятся
            целые жилые комплексы для ЛГБТ-семей. Каждый пятый молодой
            американец вступает в ЛГБТ-сообщество. И число причисляющих себя к
            таковым с каждым последующим поколением удваивается. Ну, а что
            касается предложений европейских чиновников легализовать педофилию,
            это вовсе за гранью разумного.
          </p>
          <p>
            — Название программы говорит само за себя, ее содержание направлено
            на популяризацию традиционных ценностей, защиту семьи, миссионерскую
            и просветительскую деятельность, — прокомментировала Наталья
            Дмитриевская, сопредседатель Международной общественной организации
            «Союз православных женщин». — Угрозы, которые сейчас есть в мире,
            это рост абортов, однополые браки, пропаганда ЛГБТ. Произошёл
            поворот ценностей в сторону западной идеологии, результаты которой
            мы сейчас уже можем наблюдать. Семья на Западе вырождается. А ведь
            это основа жизни и общества. И семья в России, Беларуси, других
            странах находится в уязвимом положении. Поэтому нам сейчас нужно
            обьединяться.
          </p>
          <p>
            Как рассказала Наталья Дмитриевская, в настоящий момент к
            международной программе «Семья и мир» подключились Беларусь,
            Армения, Сербия, Ливан, Грузия.
          </p>
          <p>
            — В дальнейшем мы планируем расширять партнерство, — отметила
            спикер. — Чем больше нас будет, тем более эффективными будут
            результаты работы, будь то выставки, фильмы, мастер-классы или
            дискуссионные площадки. Консолидируя усилия, масштабируя лучший опыт
            по продвижению традиционных ценностей в мировое пространство, мы
            можем стать большой сильной рекой, наполняемой разными ручейками,
            которая смоет на своем пути все фальшивое.
          </p>
          <p>
            В ходе круглого стола также выступили:
            <br /> — иерей Федор Лукьянов, председатель Патриаршей комиссии по
            вопросам семьи, защиты материнства и детства
            <br /> — «Традиционные семейные ценности в фокусе международной
            политики»;
            <br /> — Юмашева Инга Альбертовна, Председатель регионального
            отделения МОО «Союз православных женщин» в Республике Башкортостан,
            член Совета по Внешней оборонной политике, эксперт Патриаршей
            Комиссии по вопросам семьи, защиты материнства и детства — «Новый
            взгляд на институализацию системы международных отношений в сфере
            защиты семьи и брака»;
            <br /> — Костян Нина Федоровна, Председатель Бобруйской городской
            организации общественного объединения «Белорусский Союз Женщин»
            («Православные Бобруйчанки»);
            <br /> — Григорянц Анаит Ервандовна, директор филиала МОО «Союз
            православных женщин» в Республике Армения (онлайн) и другие.
          </p>
          <p>
            Во время круглого стола прозвучало немало предложений, каким образом
            можно противостоять разрушительной западной идеологии. Например,
            руководитель Патриаршей Комиссии по вопросам семьи, материнства и
            детства считает необходимым создать механизм, тестирующий все
            принимаемые законодательные акты на предмет соответствия духовным
            ценностям.
          </p>
          <p>
            — Очень важно задуматься о совершенствовании института
            государственно-общественной экспертизы нормативно-правовой базы, —
            убежден иерей Федор Лукьянов. — Если закон порывает свою связь с
            нравственностью, он перестает быть для человека обязательным…
            Поэтому можно начать с пересмотра основных законов на соответствие
            трем составляющим: суверенности, семейно-демографической
            целесообразности и нравственности.
          </p>
          <p className="">
            Официальный представитель в Беларуси Общероссийской общественной
            организации «Российский клуб православных меценатов» Александр
            Копелев убежден, что работа по продвижению традиционных семейных
            ценностей должна начинаться с подрастающего поколения.
          </p>
        </div>
      </div>
      <Link
        to={"/about?tab=presscenter"}
        className="mb-[40px] px-[38px] py-[11px] xs:px-11 xs:py-[16px] text-base leading-tight shadow-[-1px_1px_11px_6px_rgba(212,18,22,0.24)]   font-semibold border-gradient-rainred rounded-[15px] xs:rounded-[24px] justify-center  gap-2.5 inline-flex text-red xs:text-[24px] xs:font-bold xs:leading-7  transition ease-in-out hover:-translate-y-1  hover:delay-50 duration-100"
      >
        Вернуться в пресс-центр
      </Link>
      <Footer />
    </div>
  );
};

export default Page16052023;
