import React from "react";
import Footer from "../Footer";
import { Link } from "react-router-dom";

const Page09042024 = () => {
  return (
    <div className="flex flex-col justify-start items-start gap-[40px] sm:gap-[90px] ">
      <div className="w-full sm:w-5/6 flex flex-col justify-start items-start gap-[20px] sm:gap-[40px] ">
        <div className=" text-black text-2xl sm:text-5xl font-bold font-golos leading-snug mt-24 sm:mt-40 ">
          Русский дом в Александрии передал гуманитарную помощь детям
        </div>
        <div className="text-neutral-950 text-[18px] sm:text-[27px] font-normal sm:font-semibold font-golos leading-loose ">
          9 апреля 2024 года
        </div>
        <div className="text-black text-lg font-golos font-normal leading-normal flex flex-col gap-6">
          <p>
            {" "}
            8&nbsp;апреля в&nbsp;преддверии завершения священного для мусульман
            месяца Рамадан представители Русского дома совместно с&nbsp;клубом
            соотечественников &laquo;Александрия&raquo; передали в&nbsp;детский
            приют &laquo;Али ибн Абу Талеб&raquo; предметы первой необходимости
            для детей.
          </p>
          <p>
            Передача состоялась в&nbsp;рамках ежегодно проводимой
            благотворительной акции &laquo;От&nbsp;сердца к&nbsp;сердцу&raquo;,
            которая прошла при поддержке Генерального консульства России
            в&nbsp;Александрии.
          </p>
          <p>
            Участниками акции стали несколько десятков российских
            соотечественников, а&nbsp;также неравнодушных граждан Египта.
            Благодаря активности наших соотечественников удалось собрать
            продукты питания, детские летние вещи и&nbsp;игрушки, предметы быта.
            После сортировки сотрудниками приюта вся собранная помощь была
            передана детям.
          </p>
          <p>
            Русский дом в&nbsp;Александрии благодарит всех участников акции
            за&nbsp;поддержку и&nbsp;содействие при ее&nbsp;проведении.
          </p>
          <img src="/091.jpg"></img>
          <img src="/092.jpg"></img>
          <img src="/093.jpg"></img>
          <p>
            Источник:{" "}
            <a
              href="https://rs.gov.ru/news/russkij-dom-v-aleksandrii-peredal-gumanitarnuyu-pomoshh-detyam/"
              className="underline text-red"
            >
              Россотрудичество
            </a>{" "}
          </p>
        </div>
      </div>
      <Link
        to={"/about?tab=presscenter"}
        className=" mb-[40px] px-[38px] py-[11px] xs:px-11 xs:py-[16px] text-base leading-tight shadow-[-1px_1px_11px_6px_rgba(212,18,22,0.24)]   font-semibold border-gradient-rainred rounded-[15px] xs:rounded-[24px] justify-center  gap-2.5 inline-flex text-red xs:text-[24px] xs:font-bold xs:leading-7  transition ease-in-out hover:-translate-y-1  hover:delay-50 duration-100"
      >
        Вернуться в пресс-центр
      </Link>
      <Footer className="mb-20" />
    </div>
  );
};

export default Page09042024;
