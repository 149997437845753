import React from "react";
import Footer from "../Footer";
import { Link } from "react-router-dom";

const Page04042023 = () => {
  return (
    <div className="flex flex-col justify-start items-start gap-[40px] sm:gap-[90px] ">
      <div className="w-full sm:w-5/6 flex flex-col justify-start items-start gap-[20px] sm:gap-[40px] ">
        <div className=" text-black text-2xl sm:text-5xl font-bold font-golos leading-snug mt-24 sm:mt-40 ">
          Православный кинолекторий пройдёт в Минске <br />
          (Республика Беларусь)
        </div>
        <div className="text-neutral-950 text-[18px] sm:text-[27px] font-normal sm:font-semibold font-golos leading-loose ">
          4 сентября 2023 года
        </div>
        <div className="text-black text-lg font-golos font-normal leading-normal flex flex-col gap-6">
          <p>
            {" "}
            7 по 8 сентября в стенах Минской духовной академии имени святителя
            Кирилла Туровского пройдёт православный кинолекторий «Общежитие»,
            посвящённый темам семьи и детства.
          </p>
          <p>
            Организаторами выступают Международная общественная организация
            «Союз православных женщин», Общероссийская общественная организация
            «Российский Клуб Православных Меценатов», Совет по культуре Минской
            епархии Белорусской Православной Церкви, Братство в честь святого
            апостола Иоанна Богослова Минской епархии Белорусской Православной
            Церкви при содействии Федерального агентства по делам Содружества
            Независимых Государств, соотечественников, проживающих за рубежом, и
            по международному, гуманитарному сотрудничеству и Российского Фонда
            Мира.
          </p>
          <p>
            В дни работы лектория зрителям будут представлены фильмы «Мама, я
            вырасту», «Камертон счастья», «Отцы». Также состоятся встречи с
            клириком Борисовской епархии протоиереем Владимиром Шейдаком,
            клириком Санкт-Петербургской епархии иереем Давидом Бобровым, кино-
            и театральным режиссёром, сценаристом, продюсером, актёром театра и
            кино Алексеем Свиридовым.
          </p>
          <p>
            Подробнее с программой кинолектория можно ознакомиться по{" "}
            <a
              href="http://church.by//images/_conten/2023/08/2–36121/7.pdf"
              className="underline text-red"
            >
              ссылке.
            </a>{" "}
            Вход бесплатный. Для участия в мероприятии необходимо
            зарегистрироваться.
          </p>
        </div>
      </div>
      <Link
        to={"/about?tab=presscenter"}
        className=" mb-[40px] px-[38px] py-[11px] xs:px-11 xs:py-[16px] text-base leading-tight shadow-[-1px_1px_11px_6px_rgba(212,18,22,0.24)]   font-semibold border-gradient-rainred rounded-[15px] xs:rounded-[24px] justify-center  gap-2.5 inline-flex text-red xs:text-[24px] xs:font-bold xs:leading-7  transition ease-in-out hover:-translate-y-1  hover:delay-50 duration-100"
      >
        Вернуться в пресс-центр
      </Link>
      <Footer className="mb-20" />
    </div>
  );
};

export default Page04042023;
