import React from "react";
import Footer from "../Footer";
import { Link } from "react-router-dom";

const Page18052024 = () => {
  return (
    <div className="flex flex-col justify-start items-start gap-[40px] sm:gap-[90px] ">
      <div className="w-full sm:w-5/6 flex flex-col justify-start items-start gap-[20px] sm:gap-[40px] ">
        <div className=" text-black text-2xl sm:text-5xl font-bold font-golos leading-snug mt-24 sm:mt-40 ">
          В Минске прошло награждение победителей национального отбора
          международного конкурса «Праздников праздник»
        </div>
        <div className="text-neutral-950 text-[18px] sm:text-[27px] font-normal sm:font-semibold font-golos leading-loose ">
          18 мая 2024 года
        </div>
        <img src="/ep1.jpg"></img>
        <div className="text-black text-lg font-golos font-normal leading-normal flex flex-col gap-6">
          <p>
            {" "}
            Организаторами проведения национального этапа выступили Министерство
            образования Республики Беларусь, Национальный центр художественного
            творчества детей и молодежи, Синодальный отдел Белорусской
            Православной Церкви по сотрудничеству со светскими учреждениями
            образования, Общероссийская общественная организация «Российский
            клуб православных меценатов» в лице своих официальных представителей
            в Республике Беларусь.
          </p>
          <p>
            В своих работах авторы отразили богатство и разнообразие
            архитектурного наследия Белоруссии, сюжеты на библейские темы,
            изобразили портреты библейских героев, выразили свое отношение к
            светлому празднику Воскресения Христова.
          </p>
          <p>
            Как отмечают организаторы конкурса, участие в нем способствовало не
            только выявлению и поощрению талантов, но и приобщению подрастающего
            поколения к изучению исторических и культурных традиций своего
            народа, а также основ православной веры, воспитанию в детях
            трепетного отношения к православным традициям славянских народов и
            преемственности поколений.
          </p>
          <p>
            На церемонию награждения были приглашены более 120 учащихся, а также
            родителей и педагогов из всех регионов страны.
          </p>
          <p>
            Для награждения были подготовлены буклеты с фотографиями работ
            финалистов конкурса, книги, художественные материалы, памятные
            сувениры.
          </p>
          <p>
            В церемонии награждения приняли участие клирик прихода в
            храма-памятника в честь Всех святых и в память о жертвах, спасению
            Отечества нашего послуживших, протоиерей Алексий Васин, директор
            Национального центра художественного творчества детей и молодежи
            Надежда Васильченко, официальные представители в Республике Беларусь
            Общероссийской общественной организации «Российский клуб
            православных меценатов» и другие почетные гости.
          </p>
          <p>
            Дипломами I, II, III степени Министерства образования Республики
            Беларусь были награждены 11 работ национального отбора. Дипломами
            Национального центра художественного творчества детей и молодежи
            отмечены 50 финалистов.
          </p>
          <p>
            Для участников праздника был организован концерт коллективов
            Национального центр художественного творчества детей и молодежи. По
            окончании церемонии награждения всех гостей ждала экскурсия в
            Национальный мемориальный комплекс «Храм-памятник в честь Всех
            святых и в память о жертвах, спасению Отечества нашего послуживших».
          </p>
          <p>
            Работы победителей национального отбора будут направлены для участия
            в международном финале конкурса.
          </p>
          <p>
            Ожидается, что в этом году, кроме детей из Белоруссии, в конкурсе
            примут участие и их сверстники из России, Сербии, Армении, Молдовы и
            Киргизии.
          </p>
        </div>
      </div>
      <Link
        to={"/about?tab=presscenter"}
        className="mb-[40px] px-[38px] py-[11px] xs:px-11 xs:py-[16px] text-base leading-tight shadow-[-1px_1px_11px_6px_rgba(212,18,22,0.24)]   font-semibold border-gradient-rainred rounded-[15px] xs:rounded-[24px] justify-center  gap-2.5 inline-flex text-red xs:text-[24px] xs:font-bold xs:leading-7  transition ease-in-out hover:-translate-y-1  hover:delay-50 duration-100"
      >
        Вернуться в пресс-центр
      </Link>
      <Footer />
    </div>
  );
};

export default Page18052024;
