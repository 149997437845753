import React, { useEffect, useState } from "react";
import Footer from "../Footer";
import { Tilt } from "react-tilt";
import { useNavigate } from "react-router-dom";

const tilesData = [
  {
    label: "4 сентября 2023 года",
    image: "/pic1.jpg",
    description:
      "Православный кинолекторий пройдёт в Минске (Республика Беларусь)",
    url: "/page04042023",
  },
  {
    label: "5 мая 2023 года",
    image: "/placeholder.png",
    description:
      "В Айя-Напе прошел праздник, посвященный традиционным семейным ценностям ",
    url: "/page05052023",
  },
  {
    label: "8 июля 2023 года",
    image: "/pic2.jpg",
    description:
      "В Никосии прошел митинг в защиту традиционных семейных ценностей",
    url: "/page08072023",
  },

  {
    label: "10 июля 2023 года",
    image: "/pic4.jpg",
    description:
      "В Русском доме состоялась встреча Молодежного клуба, посвященная Всероссийскому празднику «День семьи, любви и верности ",
    url: "/page10072023",
  },
  {
    label: "10 июля 2023 года",
    image: "/pic7.jpg",
    description: "Состоялся вебинар «Право на жизнь» ",
    url: "/page11072023",
  },
  {
    label: "21 июля 2023 года",
    image: "/pic8.jpg",
    description:
      "Вице-спикер Госдумы Анна Кузнецова назвала важнейшие законы по сохранению традиционных ценностей",
    url: "/page21072023",
  },
];
export default function Russia() {
  const [maxBlocksPerRow, setMaxBlocksPerRow] = useState(3);
  const [maxBlockWidth, setMaxBlockWidth] = useState(32);
  const navigate = useNavigate();

  useEffect(() => {
    function handleResize() {
      let width = window.innerWidth;
      if (width < 768) {
        setMaxBlocksPerRow(1);
        setMaxBlockWidth(100);
      } else if (width < 1024) {
        setMaxBlocksPerRow(2);
        setMaxBlockWidth(47);
      } else {
        setMaxBlocksPerRow(3);
        setMaxBlockWidth(32);
      }
    }

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="flex flex-col gap-10 items-center justify-center  sm:items-start sm:justify-start ">
      <div className="flex flex-row gap-2 items-center justify-center mt-24 sm:mt-40">
        <img src="/ru.png" alt="ru" />
        <div className="text-red text-3xl md:text-6xl font-bold font-golos  ">
          Российская Федерация
        </div>
      </div>
      <div className="text-black text-lg font-semibold font-golos leading-normal"></div>
      <div className="w-full flex flex-wrap gap-5">
        {tilesData.map(
          (tile, index) =>
            tile && (
              <div
                // Изменение здесь
                className={`max-h-[700px] px-2.5 pt-2.5 pb-5 bg-white rounded-2xl shadow-[4px_4px_21px_3px_rgba(47,47,47,0.15);] flex-col justify-start items-start gap-5 inline-flex ${
                  index % maxBlocksPerRow === maxBlocksPerRow - 1
                    ? "w-full"
                    : `w-1/${maxBlocksPerRow}`
                }`}
                style={{ maxWidth: `${maxBlockWidth}%` }}
              >
                <img
                  src={tile.image}
                  className="rounded-xl w-full max-h-[310px] object-cover"
                  onClick={() => navigate(tile.url)}
                />

                <h3 className="w-52 opacity-75 text-black text-xs font-normal font-golos leading-none">
                  {tile.label}
                </h3>

                <p className="w-full text-zinc-800 text-2xl font-bold font-golos leading-7">
                  {tile.description}
                </p>
              </div>
            )
        )}
      </div>
      <Footer />
    </div>
  );
}
