import React from "react";
import Footer from "../Footer";
import { Link } from "react-router-dom";

const Page30042024 = () => {
  return (
    <div className="flex flex-col justify-start items-start gap-[40px] sm:gap-[90px] ">
      <div className="w-full sm:w-5/6 flex flex-col justify-start items-start gap-[20px] sm:gap-[40px] ">
        <div className=" text-black text-2xl sm:text-5xl font-bold font-golos leading-snug mt-24 sm:mt-40 ">
          Молодые соотечественники приглашаются к&nbsp;участию
          в&nbsp;Международном конкурсе &laquo;Моя семья в&nbsp;истории&raquo;
        </div>
        <div className="text-neutral-950 text-[18px] sm:text-[27px] font-normal sm:font-semibold font-golos leading-loose ">
          30 апреля 2024 года
        </div>
        <div className="text-black text-lg font-golos font-normal leading-normal flex flex-col gap-6">
          <p>
            {" "}
            Приглашаем молодых соотечественников в&nbsp;возрасте 18-19&nbsp;лет,
            интересующихся историей или обучающихся по&nbsp;гуманитарным
            специальностям, принять участие в&nbsp;конкурсе &laquo;Моя семья
            в&nbsp;истории&raquo;.
          </p>
          <p>
            Участникам предлагается подготовить эссе на&nbsp;русском языке
            на&nbsp;следующие темы:
          </p>
          <p>
            • история моей семьи в&nbsp;истории русской диаспоры и&nbsp; связи
            семьи с&nbsp;Россией;
            <br />
            • формы сохранения семейной памяти: устная история, семейные
            альбомы, документы и&nbsp;геральдика;
            <br />
            • места памяти моей семьи;
            <br />
            • роль историка-архивиста в&nbsp;недопущении фальсификации истории
            и&nbsp;сохранении объективного восприятия России в&nbsp;современном
            мире;
            <br />
            • моя семья в&nbsp;годы Второй мировой войны;
            <br />• невидимый фронт: память о&nbsp;Движении сопротивления
            в&nbsp;Европе.
          </p>
          <p>
            15&nbsp;победителей получат возможность принять участие в&nbsp;Школе
            историков-архивистов в&nbsp;рамках программы &laquo;Здравствуй,
            Россия!&raquo;, которая пройдёт в&nbsp;Москве этим летом.
          </p>
          <p>
            Страны, которые могут принять участие: Абхазия, Австрия,
            Азербайджан, Аргентина, Армения, Белоруссия, Бельгия, Болгария,
            Венгрия, Германия, Греция, Египет, Испания, Италия, Казахстан, Кипр,
            Киргизия, Китай, Куба, Ливан, Люксембург, Мальта, Марокко, Мексика,
            Молдова, Монголия, Польша, Португалия, Сербия, Таджикистан, Тунис,
            Турция, Узбекистан, Финляндия, Франция, Чехия, Южная Осетия.
          </p>
          <p>
            Приём заявок осуществляется представительствами Россотрудничества
            до&nbsp;30&nbsp;апреля.
          </p>

          <img src="/mic2.jpg"></img>
          <p>
            Источник:{" "}
            <a
              href="https://rs.gov.ru/news/russkij-dom-v-aleksandrii-peredal-gumanitarnuyu-pomoshh-detyam/"
              className="underline text-red"
            >
              Россотрудичество
            </a>{" "}
          </p>
        </div>
      </div>
      <Link
        to={"/about?tab=presscenter"}
        className="mb-[40px] px-[38px] py-[11px] xs:px-11 xs:py-[16px] text-base leading-tight shadow-[-1px_1px_11px_6px_rgba(212,18,22,0.24)]   font-semibold border-gradient-rainred rounded-[15px] xs:rounded-[24px] justify-center  gap-2.5 inline-flex text-red xs:text-[24px] xs:font-bold xs:leading-7  transition ease-in-out hover:-translate-y-1  hover:delay-50 duration-100"
      >
        Вернуться в пресс-центр
      </Link>
      <Footer />
    </div>
  );
};

export default Page30042024;
