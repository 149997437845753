import React from "react";
import Footer from "../Footer";
import { Link } from "react-router-dom";

const Page01042024 = () => {
  return (
    <div className="flex flex-col justify-start items-start gap-[40px] sm:gap-[90px] ">
      <div className="w-full sm:w-5/6 flex flex-col justify-start items-start gap-[20px] sm:gap-[40px] ">
        <div className=" text-black text-2xl sm:text-5xl font-bold font-golos leading-snug mt-24 sm:mt-40 ">
          Международный конкурс изобразительного искусства «ПРАЗДНИКОВ
          ПРАЗДНИК». Национальный этап Республики Беларусь
        </div>
        {/* <div className="text-neutral-950 text-[18px] sm:text-[27px] font-normal sm:font-semibold font-golos leading-loose ">
          10 июля 2023 года
        </div> */}
        <div className="text-black text-lg font-golos font-normal leading-normal flex flex-col gap-6">
          <img src="/041.jpg"></img>
          <p>
            {" "}
            Участникам конкурса предложено выразить в&nbsp;своих творческих
            работах отношение к&nbsp;Светлому празднику Воскресения Христова.
            Участие в конкурсе способствует не&nbsp;только выявлению
            и&nbsp;поощрению талантов, но&nbsp;и приобщению подрастающего
            поколения к&nbsp;изучению исторических и культурных традиций разных
            народов, а&nbsp;также основ Православной веры.
          </p>
          <img src="/042.jpg"></img>
          <p>
            Жанры конкурсных работ: Исторический&nbsp;&mdash; сюжетная
            композиция на&nbsp;
            <br />
            Библейские темы; Сюжеты, посвященные раскрытию красоты добродетелей
            в&nbsp;душе человека: доброты, милосердия, веры, надежды, любви,
            скромности, трудолюбия, любви к&nbsp;знаниям, чести, честности,
            ответственности, заботы о ближних; <br />
            Натюрморт на&nbsp;Пасхальную тематику;
            <br /> Архитектурный на&nbsp;Пасхальную тематику.
            <br /> Открытка поздравительная на&nbsp;Пасхальную тематику
            (интернет- конкурс).
          </p>
          <p>
            Конкурсные работы оцениваются членами Жюри по&nbsp;совокупности
            следующих критериев: &bull;соответствие тематике Конкурса
            и&nbsp;глубина; &bull;оригинальность и&nbsp;образная
            &bull;выразительность произведения; исполнительское мастерство;
            &bull;художественно-образное решение конкурсной работы.
          </p>
          <img src="/043.jpg"></img>
          <p>
            На&nbsp;фотографиях представлена работа профессионального жюри
            на&nbsp;Национальном этапе Республики Беларусь. В&nbsp;Беларуси
            приняло участие в&nbsp;проекте почти две тысячи детей!
          </p>
          <p>
            Финал нашего конкурса уже совсем близко 🙏🏻 Следите за&nbsp;нашими
            новостями!
          </p>
          <img src="/043.jpg"></img>
          <img src="/044.jpg"></img>
          <img src="/045.jpg"></img>
          <img src="/046.jpg"></img>
          <img src="/047.jpg"></img>
          <img src="/048.jpg"></img>
          <img src="/049.jpg"></img>
          <img src="/0410.jpg"></img>
          <img src="/0411.jpg"></img>
          <img src="/0412.jpg"></img>
          <img src="/0413.jpg"></img>
          <img src="/0414.jpg"></img>
        </div>
      </div>
      <Link
        to={"/about?tab=presscenter"}
        className="mb-[40px] px-[38px] py-[11px] xs:px-11 xs:py-[16px] text-base leading-tight shadow-[-1px_1px_11px_6px_rgba(212,18,22,0.24)]   font-semibold border-gradient-rainred rounded-[15px] xs:rounded-[24px] justify-center  gap-2.5 inline-flex text-red xs:text-[24px] xs:font-bold xs:leading-7  transition ease-in-out hover:-translate-y-1  hover:delay-50 duration-100"
      >
        Вернуться в пресс-центр
      </Link>
      <Footer />
    </div>
  );
};

export default Page01042024;
