import React from "react";

function Contest() {
  React.useEffect(() => {
    window.location.href = "https://prazdnikcontest.tilda.ws/";
  }, []);

  return null;
}

export default Contest;
