import React from "react";
import Footer from "../Footer";
import { Link } from "react-router-dom";

const Page11072023 = () => {
  return (
    <div className="flex flex-col justify-start items-start gap-[40px] sm:gap-[90px] ">
      <div className="w-full sm:w-5/6 flex flex-col justify-start items-start gap-[20px] sm:gap-[40px] ">
        <div className=" text-black text-2xl sm:text-5xl font-bold font-golos leading-snug mt-24 sm:mt-40 ">
          Состоялся вебинар <br /> «Право на жизнь»
        </div>
        <div className="text-neutral-950 text-[18px] sm:text-[27px] font-normal sm:font-semibold font-golos leading-loose ">
          10 июля 2023 года
        </div>
        <div className="text-black text-lg font-golos font-normal leading-normal flex flex-col gap-6">
          <p>
            {" "}
            В преддверии Всероссийского праздника — Дня семьи, любви и верности,
            в рамках международной программы «Семья и мир» прошёл вебинар «Право
            на жизнь».
          </p>
          <p>
            Это просветительское онлайн-мероприятие было направлено на то, чтобы
            пролить свет на различные аспекты, связанные со здоровьем женщин и
            важностью защиты нерожденных жизней.
          </p>
          <img src="/pic7.jpg"></img>
          <p>
            Вебинар затронул широкий круг тем, каждая из которых имела решающее
            значение для понимания физиологических и психологических аспектов
            жизни женщины. Вот краткий обзор основных обсуждаемых вопросов:
          </p>
          <p>
            — Физиология и гормональный фон женщины: участники получили ценную
            информацию об общей информации о физиологических аспектах женского
            тела, включая гормональные изменения, происходящие на разных этапах
            жизни.
          </p>

          <p>
            — Причины женского бесплодия: были рассмотрены психологические и
            физиологические факторы, которые могут способствовать женскому
            бесплодию, подчеркнута важность ранней диагностики и правильного
            лечения.
          </p>
          <p>
            — Здоровая беременность: начинающим родителям были предоставлены
            советы экспертов по сохранению здоровой беременности, охватывающие
            как психологические, так и физиологические аспекты. Это включало
            рекомендации по правильному питанию, физическим упражнениям и
            эмоциональному благополучию для будущих матерей.
          </p>
          <p>
            — Профилактика послеродовой депрессии. Признавая важность
            психического здоровья в послеродовой период, на вебинаре были
            освещены эффективные стратегии предотвращения и лечения послеродовой
            депрессии.
          </p>
          <p>
            — Прерывание беременности и последствия: участники вдумчиво
            обсуждали психологические и физиологические аспекты прерывания
            беременности.
          </p>
          <p>
            — Причины, предполагающие аборт: на вебинаре были рассмотрены
            различные причины, которые могут побудить кого-то задуматься об
            аборте.
          </p>
          <p>
            — Как предотвратить аборт: опираясь на личный опыт, поделились
            практическими историями и рассказами о конкретных жизненных
            ситуациях, которые поразили и умилили всех слушателей.
          </p>
          <p>
            — Пренатальная психология. Чтобы углубить понимание сложного мира
            психологии плода, на вебинаре были рассмотрены ранние этапы развития
            человека и важность заботы об эмоциональном благополучии как матери,
            так и будущего ребенка.
          </p>
          <p>
            Вебинар «Право на жизнь» стал платформой для воспитания сострадания,
            сопереживания и обмена знаниями. Обращаясь к этим жизненно важным
            аспектам женского здоровья и подчеркивая важность защиты еще не
            родившихся жизней, он стремился расширить возможности семей и
            продвигать традиционные ценности.
          </p>
          <p>
            Выражаем огромную благодарность ведущей — Мисакян Лилит Максимовне и
            участникам за их активное участие в этом важном мероприятии.
          </p>
          <p>
            Продолжаем вместе работать над созданием мира, в котором право на
            жизнь ценится и защищается на каждом этапе.
          </p>
          <p className="text-red">
            Пресс-служба МОО «Союз православных женщин»
          </p>
        </div>
      </div>
      <Link
        to={"/about?tab=presscenter"}
        className="mb-[40px] px-[38px] py-[11px] xs:px-11 xs:py-[16px] text-base leading-tight shadow-[-1px_1px_11px_6px_rgba(212,18,22,0.24)]   font-semibold border-gradient-rainred rounded-[15px] xs:rounded-[24px] justify-center  gap-2.5 inline-flex text-red xs:text-[24px] xs:font-bold xs:leading-7  transition ease-in-out hover:-translate-y-1  hover:delay-50 duration-100"
      >
        Вернуться в пресс-центр
      </Link>
      <Footer />
    </div>
  );
};

export default Page11072023;
